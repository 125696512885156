import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet as get } from '@styled-system/theme-get';
import {
  color,
  width,
  maxWidth,
  textAlign,
  fontSize,
  fontFamily,
  lineHeight,
  fontWeight,
  display,
  space,
} from 'styled-system';

const fontSizeDefault = (props) => {
  const { size } = props;
  const fsHead = 'fontSizes.heading';
  const mobile = get(`${fsHead}.mobile.default`)(props);
  const setValue = () => {
    switch (size) {
      case 'hero':
        return [mobile + 15, get(`${fsHead}.hero`)(props)];
      case 'h1':
        return [mobile + 10, get(`${fsHead}.h1`)(props)];
      case 'h2':
        return [mobile, get(`${fsHead}.h2`)(props)];
      case 'h3':
        return [mobile, get(`${fsHead}.h3`)(props)];
      case 'h4':
        return [mobile, get(`${fsHead}.h4`)(props)];
      case 'h5':
        return [mobile, get(`${fsHead}.h5`)(props)];
      default:
        return mobile;
    }
  };
  return {
    fontSize: setValue(),
    ...props,
  };
};

const lineHeightDefault = (props) => {
  const { size } = props;
  const lhHead = 'lineHeights.heading';
  const mobile = get(`${lhHead}.mobile.default`)(props);
  const setValue = () => {
    switch (size) {
      case 'hero':
      case 'h1':
        return [mobile, get(`${lhHead}.h1`)(props)];
      case 'h2':
        return [mobile, get(`${lhHead}.h2`)(props)];
      case 'h3':
        return [mobile, get(`${lhHead}.h3`)(props)];
      case 'h4':
        return [mobile, get(`${lhHead}.h4`)(props)];
      case 'h5':
        return [mobile, get(`${lhHead}.h5`)(props)];
      default:
        return mobile;
    }
  };
  return {
    lineHeight: setValue(),
    ...props,
  };
};

const marginDefault = ({ size, m, mt, mb, ...props }) => {
  const setValue = () => {
    switch (size) {
      case 'hero':
      case 'h1':
        return 'xxl';
      case 'h2':
      case 'h3':
        return 'l';
      case 'h4':
      case 'h6':
        return 's';
      default:
        return 'm';
    }
  };
  return {
    m: m !== undefined ? m : null,
    mt: m === undefined ? mt : null,
    mb: m === undefined ? mb || setValue() : null,
    ...props,
  };
};

const StyledTitle = styled.h2`
  ${display};
  ${width};
  ${maxWidth};
  ${color};
  ${textAlign};
  ${fontWeight};
  ${fontFamily};
  ${(props) => lineHeight(lineHeightDefault(props))};
  ${(props) => fontSize(fontSizeDefault(props))};
  ${(props) => space(marginDefault(props))};
`;

const Title = (props) => <StyledTitle {...props} />;

Title.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hero']),
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  color: PropTypes.string,
  ...width.propTypes,
  ...textAlign.propTypes,
  ...fontSize.propTypes,
  ...lineHeight.propTypes,
  ...maxWidth.propTypes,
  ...display.propTypes,
  ...space.propTypes,
};

Title.defaultProps = {
  as: 'h2',
  size: 'h2',
  fontFamily: 'headings',
  fontWeight: 400,
  color: 'brand.blue',
  mt: 0,
};

export default Title;
