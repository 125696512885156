import gql from 'graphql-tag';

export default gql`
  query getBooking($reference: String!) {
    user: Member {
      id
      last4
      email
      firstName
      surname
      phoneNumber
      contactPermission
    }
    booking: Booking(reference: $reference) {
      id
      reference
      date
      time
      email
      people
      price
      amountToPay
      currency
      credit
      specialConditions
      status
      redirectURL
      hasReview
      reservationConfirmation
      shareData
      dinedPreviously
      conversionData
      availability {
        id
        leaveTime
        leaveTimes
        allowedDiners
      }
      restaurant {
        id
        title
        menuTitle
        additionalPeopleAllowed
        additionalPeopleForbidden
        askForDietaryRequirements
        integrationList
        region {
          menuTitle
          slug
          id
        }
      }
    }
  }
`;
