import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color, space, top, left, display } from 'styled-system';
import { ReactComponent as IconArrowDown } from './icon/s/arrow-down.s.svg';
import { ReactComponent as IconArrowLeftS } from './icon/s/arrow-left.s.svg';
import { ReactComponent as IconArrowRightS } from './icon/s/arrow-right.s.svg';
import { ReactComponent as IconArrowUp } from './icon/s/arrow-up.s.svg';
import { ReactComponent as IconCloseS } from './icon/s/close.s.svg';
import { ReactComponent as IconMapMarkerS } from './icon/s/map-marker.s.svg';
import { ReactComponent as IconReportS } from './icon/s/report.s.svg';
import { ReactComponent as IconRankS } from './icon/s/rank.s.svg';
import { ReactComponent as IconInstantBook } from './icon/m/instant-book.s.svg';
import { ReactComponent as IconMapMarkerM } from './icon/m/map-marker.s.svg';
import { ReactComponent as IconFilter } from './icon/m/filter.s.svg';
import { ReactComponent as IconFork } from './icon/m/fork.s.svg';
import { ReactComponent as IconLike } from './icon/m/like.s.svg';
import { ReactComponent as IconLikeFill } from './icon/m/like-fill.s.svg';
import { ReactComponent as IconArrowLeftM } from './icon/m/arrow-left.s.svg';
import { ReactComponent as IconArrowRightM } from './icon/m/arrow-right.s.svg';
import { ReactComponent as IconCloseM } from './icon/m/close-m.s.svg';
import { ReactComponent as IconCloseMBold } from './icon/m/close-m-bold.svg';
import { ReactComponent as IconList } from './icon/m/list.s.svg';
import { ReactComponent as IconLocations } from './icon/m/locations.s.svg';
import { ReactComponent as IconReportM } from './icon/m/report.s.svg';
import { ReactComponent as IconShare } from './icon/m/share.s.svg';
import { ReactComponent as IconSearch } from './icon/m/search.s.svg';
import { ReactComponent as IconMoreV } from './icon/m/more-v.s.svg';
import { ReactComponent as IconCalendar } from './icon/m/calendar.s.svg';
import { ReactComponent as IconNearMe } from './icon/m/near-me.s.svg';
import { ReactComponent as IconMapM } from './icon/m/map.s.svg';
import { ReactComponent as IconSpoonM } from './icon/m/spoon.s.svg';
import { ReactComponent as IconBookM } from './icon/m/book.s.svg';
import { ReactComponent as IconQuote } from './icon/m/quote.s.svg';
import { ReactComponent as IconContacts } from './icon/m/contacts.svg';
import { ReactComponent as IconFacebook } from './icon/l/facebook.s.svg';
import { ReactComponent as IconFacebook2 } from './icon/l/facebook-2.s.svg';
import { ReactComponent as IconInstagram } from './icon/l/instagram.s.svg';
import { ReactComponent as IconYoutube } from './icon/l/youtube.s.svg';
import { ReactComponent as IconTwitter } from './icon/l/twitter.s.svg';
import { ReactComponent as IconMoreSocial } from './icon/l/more-social.s.svg';
import { ReactComponent as IconRankL } from './icon/l/rank.s.svg';
import { ReactComponent as IconLinkedin } from './icon/l/linkedin.svg';
import { ReactComponent as IconLinkedin2 } from './icon/l/linkedin2.svg';
import { ReactComponent as IconWebsite } from './icon/l/globe.svg';
import { ReactComponent as IconDiscount } from './icon/xl/discount.s.svg';
import { ReactComponent as IconDiscovery } from './icon/xl/discovery.s.svg';
import { ReactComponent as IconReviews } from './icon/xl/reviews.s.svg';
import { ReactComponent as IconArrowDownNav } from './icon/nav/arrow-down.s.svg';
import { ReactComponent as IconArrowUpNav } from './icon/nav/arrow-up.s.svg';
import { ReactComponent as IconArrowLeftNav } from './icon/nav/arrow-left.s.svg';
import { ReactComponent as IconArrowRightNav } from './icon/nav/arrow-right.s.svg';
import { ReactComponent as IconCloseNav } from './icon/nav/close.s.svg';
import { ReactComponent as IconMinus } from './icon/nav/minus.s.svg';
import { ReactComponent as IconPlus } from './icon/nav/plus.s.svg';
import { ReactComponent as IconLogo } from './icon/other/logo.s.svg';
import { ReactComponent as IconLogoBird } from './icon/other/logo-bird.s.svg';
import { ReactComponent as IconBirdLogo } from './icon/nav/bird.s.svg';
import { ReactComponent as IconChair } from './icon/other/chair.s.svg';
import { ReactComponent as IconCutlery } from './icon/other/cutlery.svg';
import { ReactComponent as IconCopy } from './icon/l/copy.svg';
import { ReactComponent as IconPayment } from './icon/m/payment.svg';

const styles = css`
  position: relative;
  vertical-align: middle;
  ${color};
  ${space};
  ${top};
  ${left};
  ${display};
`;

const getIcon = (name) => {
  switch (name) {
    case 's-arrow-down':
      return IconArrowDown;
    case 's-arrow-left':
      return IconArrowLeftS;
    case 's-arrow-right':
      return IconArrowRightS;
    case 's-arrow-up':
      return IconArrowUp;
    case 's-close':
      return IconCloseS;
    case 's-map-marker':
      return IconMapMarkerS;
    case 's-report':
      return IconReportS;
    case 's-rank':
      return IconRankS;
    case 'm-instant-book':
      return IconInstantBook;
    case 'm-map':
      return IconMapM;
    case 'm-map-marker':
      return IconMapMarkerM;
    case 'm-filter':
      return IconFilter;
    case 'm-fork':
      return IconFork;
    case 'm-like':
      return IconLike;
    case 'm-like-fill':
      return IconLikeFill;
    case 'm-arrow-left':
      return IconArrowLeftM;
    case 'm-arrow-right':
      return IconArrowRightM;
    case 'm-close-m':
      return IconCloseM;
    case 'm-close-m-bold':
      return IconCloseMBold;
    case 'm-list':
      return IconList;
    case 'm-locations':
      return IconLocations;
    case 'm-report':
      return IconReportM;
    case 'm-share':
      return IconShare;
    case 'm-search':
      return IconSearch;
    case 'm-more-v':
      return IconMoreV;
    case 'm-calendar':
      return IconCalendar;
    case 'm-near-me':
      return IconNearMe;
    case 'm-spoon':
      return IconSpoonM;
    case 'm-book':
      return IconBookM;
    case 'm-quote':
      return IconQuote;
    case 'm-contacts':
      return IconContacts;
    case 'l-rank':
      return IconRankL;
    case 'l-facebook':
      return IconFacebook;
    case 'l-facebook-2':
      return IconFacebook2;
    case 'l-instagram':
      return IconInstagram;
    case 'l-youtube':
      return IconYoutube;
    case 'l-twitter':
      return IconTwitter;
    case 'l-more-social':
      return IconMoreSocial;
    case 'l-website':
      return IconWebsite;
    case 'l-linkedin':
      return IconLinkedin2;
    case 'xl-discount':
      return IconDiscount;
    case 'xl-discovery':
      return IconDiscovery;
    case 'xl-reviews':
      return IconReviews;
    case 'nav-arrow-left':
      return IconArrowLeftNav;
    case 'nav-arrow-right':
      return IconArrowRightNav;
    case 'nav-arrow-down':
      return IconArrowDownNav;
    case 'nav-arrow-up':
      return IconArrowUpNav;
    case 'nav-close':
      return IconCloseNav;
    case 'nav-minus':
      return IconMinus;
    case 'nav-plus':
      return IconPlus;
    case 'logo':
      return IconLogo;
    case 'bird-logo':
      return IconBirdLogo;
    case 'logo-bird':
      return IconLogoBird;
    case 'chair':
      return IconChair;
    case 'cutlery':
      return IconCutlery;
    case 'linkedin':
      return IconLinkedin;
    case 'copy':
      return IconCopy;
    case 'payment':
      return IconPayment;
    default:
      return undefined;
  }
};

const AnIcon = ({ name, ...props }) => getIcon(name)(props);

const StyledIcon = styled(AnIcon)`
  ${styles}
`;

const Icon = (props) => <StyledIcon {...props} />;

Icon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string.isRequired,
  ...space.propTypes,
  ...top.propTypes,
  ...left.propTypes,
};
Icon.defaultProps = {
  color: 'brand.blue',
};

export default Icon;
