"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countryData = exports.countryISOCode = void 0;
var countryISOCode = function (siteId) {
    var countryCode;
    switch (siteId) {
        case 1:
            countryCode = 'NZ';
            break;
        case 2:
            countryCode = 'AU';
            break;
        case 3:
            countryCode = 'AE';
            break;
        case 4:
            countryCode = 'CA';
            break;
        case 7:
            countryCode = 'DE';
            break;
        case 8:
            countryCode = 'UK';
            break;
        case 9:
            countryCode = 'IN';
            break;
        case 10:
            countryCode = 'ZA';
            break;
        case 11:
            countryCode = 'IE';
            break;
        case 12:
            countryCode = 'ID';
            break;
        case 13:
            countryCode = 'SG';
            break;
        case 14:
            countryCode = 'GLOBAL';
            break;
        case 15:
            countryCode = 'PH';
            break;
        case 17:
            countryCode = 'KY';
            break;
        default:
            countryCode = 'NZ';
    }
    return countryCode;
};
exports.countryISOCode = countryISOCode;
// export const isMinorSite = (function minorSite() {
//   return (
//     [3, 7, 9, 10, 13, 14, 15, 17].indexOf(+process.env.GATSBY_SITE_ID) !== -1
//   );
// }());
// module.exports.isGlobalSite = (function globalSite() {
//   return +process.env.GATSBY_SITE_ID === 14;
// }());
/**
 * Handle various hardcoded data for our sites
 * @returns {{signupUrl: string, infopackUrl: string}}
 */
var countryData = function (siteId) {
    var data = {
        infopackUrl: 'https://resources.firsttable.com/global/download-the-first-table-info-pack',
        signupUrl: 'https://resources.firsttable.com/global/sign-up-your-restaurant',
        countryCode: (0, exports.countryISOCode)(siteId),
    };
    switch (siteId) {
        case 1:
            data.infopackUrl =
                'https://resources.firsttable.com/global/download-the-first-table-info-pack?country=New%20Zealand';
            data.signupUrl =
                'https://resources.firsttable.com/nz/sign-up-your-restaurant?country=New%20Zealand';
            data.schedulerLink = '/restaurateurs/schedule-a-personal-call/';
            break;
        case 2:
            data.infopackUrl =
                'https://resources.firsttable.com/global/download-the-first-table-info-pack?country=Australia';
            data.signupUrl =
                'https://resources.firsttable.com/au/sign-up-your-restaurant?country=Australia';
            data.schedulerLink = '/restaurateurs/schedule-a-personal-call/';
            break;
        case 4:
            data.infopackUrl =
                'https://resources.firsttable.com/global/download-the-first-table-info-pack?country=Canada';
            data.signupUrl =
                'https://resources.firsttable.com/ca/sign-up-your-restaurant?country=Canada';
            data.schedulerLink =
                'https://resources.firsttable.com/meetings/raven-from-first-table/zoom-or-phone-chat-about-first-table';
            break;
        case 8:
            data.infopackUrl =
                'https://resources.firsttable.com/global/download-the-first-table-info-pack?country=United%20Kingdom';
            data.signupUrl =
                'https://resources.firsttable.com/uk/sign-up-your-restaurant?country=United%20Kingdom';
            data.schedulerLink = '/restaurateurs/schedule-a-personal-call/';
            break;
        case 11:
            data.infopackUrl =
                'https://resources.firsttable.com/global/download-the-first-table-info-pack?country=Ireland';
            data.signupUrl =
                'https://resources.firsttable.com/ie/sign-up-your-restaurant?country=Ireland';
            data.schedulerLink = '/restaurateurs/schedule-a-personal-call/';
            break;
        case 12:
            data.infopackUrl =
                'https://resources.firsttable.com/global/download-the-first-table-info-pack?country=Indonesia';
            data.signupUrl =
                'https://resources.firsttable.com/id/sign-up-your-restaurant?country=Indonesia';
            data.schedulerLink =
                'https://resources.firsttable.com/meetings/peter-from-first-table/zoom-or-phone-chat-about-first-table';
            break;
        case 17:
            data.signupUrl =
                'https://resources.firsttable.com/ky/sign-up-your-restaurant?country=Caymans';
            break;
        default:
    }
    return data;
};
exports.countryData = countryData;
