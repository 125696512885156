import React, { useContext } from 'react';
import ModalContext from '../context/ModalContext';

const withModal = (ComponentToWrap) => (props) => {
  const { showModalFunc, hideModalFunc, isModalOpen } = useContext(
    ModalContext,
  );
  return (
    <ComponentToWrap
      showModal={showModalFunc}
      hideModal={hideModalFunc}
      isModalOpen={isModalOpen}
      {...props}
    />
  );
};

export default withModal;
