import React from 'react';
import useGlobalHook from './global';

const initialState = {
  backUrl: null,
};

const actions = {
  setBackUrl: (store, backUrl) => {
    store.setState({ backUrl });
  },
};

const useGlobal = useGlobalHook(React, initialState, actions);

const useBackUrl = () => {
  const [backUrl, setBackUrl] = useGlobal();
  return {
    ...backUrl,
    ...setBackUrl,
  };
};

export default useBackUrl;
