import gql from 'graphql-tag';

export default gql`
  mutation updateUser($userInput: MemberInput!) {
    updateUser(userInput: $userInput) {
      id
      firstName
      surname
      email
      noFacebook
      noInstagram
      contactPermission
    }
  }
`;
