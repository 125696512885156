/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../atoms/Box';
import Text from '../../atoms/Text';
import RadioButton from '../InputRadio';

const InputRadioGroup = ({
  name,
  onChange, onBlur,
  touched, value,
  inline, error, options, ...props
}) => (
  <Box>
    {options.map((opt, i) => {
      const id = `${name}_${i}`;
      const { inputValue } = opt;
      return (
        <Box
          key={i}
          display={inline ? 'inline' : 'block'}
          mr={inline ? '15px' : null}
          mb={!inline ? '5px' : null}
        >
          <RadioButton
            {...props}
            id={id}
            name={name}
            label={opt.inputLabel}
            type="radio"
            description={opt.description}
            value={inputValue}
            selected={value === inputValue}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Box>
      );
    })}
    {touched && error && <Text color="error" fontSize="xs" mt="5px" mb="0">{error}</Text>}
  </Box>
);

InputRadioGroup.propTypes = {
  inline: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
};
InputRadioGroup.defaultProps = {
  inline: false,
  touched: false,
  error: '',
  options: [],
  value: null,
};

export default InputRadioGroup;
