import React from 'react';
import PropTypes from 'prop-types';
import {
  AlertComponentPropsWithStyle,
  positions,
  Provider as AlertProvider,
  transitions,
} from 'react-alert';
import { ApolloProvider } from '@apollo/client';
import WebTheme from '@firsttable/web-theme/layout';
import { Alert, Box } from '@firsttable/web-components/atoms';
import { initializeApollo } from '../apollo';
import { ModalProvider } from '../context/ModalContext';
import { Provider as AuthProvider } from '../context/AuthContext';
import '../css/scss/base.scss';
import '../css/scss/modal.scss';
import '../css/scss/swiper-core-5.1.0.scss';
import '../css/scss/photoswipe/main.scss';
import '../css/scss/photoswipe/default-skin/default-skin.scss';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const AlertTemplate: React.ComponentType<AlertComponentPropsWithStyle> = ({
  options,
  close,
  ...props
}: {
  options?: any;
  close: () => void;
}) => (
  <ModalProvider>
    <WebTheme>
      <Box m={15} {...options.containerStyle}>
        <Alert options={options} close={options.close || close} {...props} />
      </Box>
    </WebTheme>
  </ModalProvider>
);

AlertTemplate.propTypes = {
  options: PropTypes.any,
  close: PropTypes.func.isRequired,
};

AlertTemplate.defaultProps = {
  options: {},
};

const alertOptions = {
  offset: '25px',
  timeout: 3000,
  transition: transitions.SCALE,
};

const App = ({ children }: { children: any }) => {
  const client = initializeApollo();
  return (
    <ApolloProvider client={client}>
      <AlertProvider
        template={AlertTemplate}
        position={positions.BOTTOM_RIGHT}
        {...alertOptions}
      >
        <ModalProvider>
          <AuthProvider>{children}</AuthProvider>
        </ModalProvider>
      </AlertProvider>
    </ApolloProvider>
  );
};

App.propTypes = {
  children: PropTypes.any.isRequired,
};
App.defaultProps = {};

export default App;
