import React, { useContext } from 'react';
import {
  Box,
  Container,
  Text,
  Row,
  Col,
  Title,
  Icon,
  Flex,
} from '@firsttable/web-components/atoms';
import { breakpoints, space } from '@firsttable/web-theme';
import { getUser } from '@firsttable/functions';
import useBackUrl from '../../../hooks/backUrl';
import ModalContext from '../../../context/ModalContext';
import MoreInfoForm from './MoreInfoForm';

const SignUpMoreInfo = () => {
  const { hideModalFunc } = useContext(ModalContext);
  const { backUrl } = useBackUrl();
  const userData = getUser();
  return (
    <Container
      overflow="hidden"
      maxWidth={[breakpoints[2], 'none']}
      pl={[space.s, 0]}
      pr={[space.s, 0]}
    >
      <Row>
        <Col
          minHeight="100vh"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Box m="auto">
            <Box
              position="relative"
              width="100%"
              maxWidth="508px"
              p="20px"
              bg="white"
            >
              <Flex mb="40px" justifyContent="center">
                <Icon name="logo" color="brand.blue" />
              </Flex>
              <Title as="h3" textAlign="center">
                Sign up with Facebook
              </Title>
              <Text>
                Hi {userData.firstName}, we&lsquo;ve got your details from
                Facebook.
              </Text>
              <Text>
                Now we just need to confirm your email address and get you to
                agree to our terms and conditions, and kindly ask you for
                permission to contact you.
              </Text>
              <MoreInfoForm
                userData={userData}
                hideModal={hideModalFunc}
                backUrl={backUrl}
              />
            </Box>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpMoreInfo;
