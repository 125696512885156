import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
// @todo next-dev: import './css/base.css'; for gatsby project
import { themeGet } from '@styled-system/theme-get';
import * as theme from './index';

type BaseStylesProps = {
    footer?: any;
};

export const BaseStyles = styled.div < BaseStylesProps > `
  display: ${({ footer }) => (footer ? 'flex' : null)};
  flex-direction: ${({ footer }) => (footer ? 'column' : null)};
  min-height: ${({ footer }) => (footer ? '100vh' : null)};
  font-family: ${themeGet('fonts.body')};
  color: ${themeGet('colors.black')};
  line-height: ${themeGet('lineHeights.m')};
`;

const layout = ({ children, footer }: { children: any; footer?: any; }) => (
  <ThemeProvider theme={theme}>
    <BaseStyles footer={footer} role="main">
      {children}
    </BaseStyles>
  </ThemeProvider>
);

export default layout;
