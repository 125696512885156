import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';

import 'firebase/remote-config';

import WebTheme from '@firsttable/web-theme/layout';
import { Container, Text, Title } from '@firsttable/web-components/atoms';
import { ErrorBoundary } from 'react-error-boundary';

import App from './src/components/App';
import Logger from './src/helpers/logger';

if (process.env.DEBUG_LEVEL && process.env.NODE_ENV !== 'production') {
  Logger.LOG_LEVEL = process.env.DEBUG_LEVEL;
}

ReactModal.setAppElement('#___gatsby'); // react modal throws a tantrum in develop

export const onClientEntry = () => {
  // shim for IE 11
  if (!('classList' in SVGElement.prototype)) {
    Object.defineProperty(SVGElement.prototype, 'classList', {
      get() {
        return {
          contains: (className) =>
            this.className.baseVal.split(' ').indexOf(className) !== -1,
        };
      },
    });
  }
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  if (code) {
    Cookies.set('code', code, { expires: 365 });
    // utm and cookie
    // hasLoggedIn = 1
  }
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://d5f566d8509a47cbac74e7a57d307d89@sentry.io/1382545',
      // Optional settings, see https://docs.sentry.io/clients/node/config/#optional-settings
      environment: process.env.NODE_ENV,
    });
  }
};

const errorHandler = (error, componentStack) => {
  Sentry.withScope((scope) => {
    scope.setExtra('componentStack', componentStack);
    Sentry.captureException(error);
  });
};

const FallbackComponent = () => (
  <WebTheme>
    <Container>
      <Title is="h1">
        <strong>Oops! An error occured!</strong>
      </Title>
      <Text>
        We&lsquo;ve been alerted, and working on getting things back to normal
        again.
      </Text>
    </Container>
  </WebTheme>
);

FallbackComponent.propTypes = {};

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary onError={errorHandler} FallbackComponent={FallbackComponent}>
    <App>{element}</App>
  </ErrorBoundary>
);

export const onRouteUpdate = () => {
  const selector = document.getElementsByClassName('modal-checkbox');
  document.body.classList.remove('modal-open');
  if (selector) {
    Array.from(selector).forEach((element) => {
      element.addEventListener('change', () => {
        if (element.checked) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      });
    });
  }
  document.onkeydown = function handleModalClose(evt) {
    const keyEvent = evt || window.event;
    if (
      keyEvent.code === 'Escape' &&
      document.body.classList.contains('modal-open')
    ) {
      // escape
      const selector = document.getElementsByClassName('modal-checkbox');
      Array.from(selector).forEach((element) => {
        element.checked = false;
      });
      document.body.classList.remove('modal-open');
    }
  };
};

wrapRootElement.propTypes = {
  element: PropTypes.any.isRequired,
};
