import { InMemoryCache, makeVar } from '@apollo/client';

export const redirectPath = makeVar<string | null>(null);
export const search = makeVar<{
  restaurantSort: string;
  selectedDate: string;
  city: string | number;
  session: string;
  userLat: number;
  userLng: number;
}>({
  restaurantSort: 'default',
  selectedDate: 'any',
  city: '',
  session: 'dinner',
  userLat: 0,
  userLng: 0,
});

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        search: {
          read() {
            return search();
          },
        },
        redirectPath: {
          read() {
            return redirectPath();
          },
        },
      },
    },
  },
});
