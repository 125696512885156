import gql from 'graphql-tag';

export default gql`
  mutation updateReview($reviewId: String, $comment: String) {
    updateReview(reviewId: $reviewId, comment: $comment) {
      comment
      id
    }
  }
`;
