import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import { BoxProps } from '../Box/Box';

const Text = ({ m, ...props }: BoxProps & { forwardedAs?: any; css?: any }) => (
  <Box m={m} {...props} />
);

Text.propTypes = {
  as: PropTypes.string,
  m: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
};
Text.defaultProps = {
  as: 'p',
  m: '0 0 1.12em',
};
Text.displayName = 'Text';

export default Text;
