import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { scroller } from 'react-scroll';

const FocusError = ({ isModal }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const containerId = isModal ? 'ReactModal' : null;
        scroller.scrollTo(keys[0], {
          duration: 1000,
          smooth: true,
          containerId,
        });
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
