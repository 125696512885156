import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  space,
  color,
  textAlign,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { BoxProps } from '../Box/Box';

const spaceDefault = ({ mt, mb, m, ...rest }: any) => ({
  m: m !== undefined ? m : null,
  mt: m === undefined ? mt : null,
  mb: m === undefined ? mb : null,
  ...rest,
});

const fontSizeDefault = (props: any) => {
  const { size } = props;
  return {
    fontSize: `${themeGet(`fontSizes.bodyHeading.${size}`)(props)}px`,
    ...props,
  };
};

const lineHeightDefault = (props: any) => {
  const { size } = props;
  return {
    lineHeight: themeGet(`lineHeights.bodyHeading.${size}`)(props),
    ...props,
  };
};

interface BodyTitleProps {
  size?: string | number;
  children?: React.ReactNode;
  as?: any;
  color?: any;
}

const StyledBodyTitle = styled.div`
  ${fontWeight};
  ${textAlign};
  ${color};
  ${letterSpacing};
  ${(props) => lineHeight(lineHeightDefault(props))};
  ${(props) => space(spaceDefault(props))};
  ${(props) => fontSize(fontSizeDefault(props))};
`;

const BodyTitle = (props: BoxProps & BodyTitleProps) => (
  <StyledBodyTitle {...props} />
);

BodyTitle.propTypes = {
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  letterSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  as: PropTypes.string,
  mt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  mb: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

BodyTitle.defaultProps = {
  size: 'm',
  fontWeight: 'bold',
  as: 'h3',
  mt: 0,
  mb: 20,
  letterSpacing: '-0.03em',
};

BodyTitle.displayName = 'BodyTitle';

export default BodyTitle;
