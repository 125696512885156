import themeGet from './themeGet';
import styledSpaces from './styledSpaces';

const colors = {
  black: '#272b2e',
  white: '#fff',
  grayscale: {
    600: '#99a3ab',
    400: '#d7dbdE',
    300: '#ebeff2',
    200: '#f6f8f9',
  },
  brand: {
    blue: '#265682',
    red: '#ff685d',
    primary: '#265682',
    secondary: '#ff685d',
  },
  gold: {
    900: '#c7a77b',
    800: '#d6bb96',
    600: '#ebe4da',
    400: '#fbf6f0',
  },
  success: '#1cbd9c',
  error: '#ff685d',
  warning: {
    dark: '#ab810a',
    light: '#feeeb6',
  },
  overlay: 'rgba(0, 0, 0, 0.5)',
  buttons: {
    cta: {
      hover: '#ff756b',
      active: '#f55a4e',
    },
    outline: {
      hover: '#ebeff2',
      active: '#f6f8f9',
    },
    normal: {
      hover: '#2c6599',
      active: '#224f78',
    },
    facebook: {
      hover: '#516aa0',
      active: '#3b5998',
    },
  },
  inputBorder: '#e6e8e8',
  avatars: {
    orange: '#ffb45d',
    blue: '#5d9eff',
  },
  footer: {
    bg: '#234563',
    btn: 'rgba(255, 255, 255, 0.07)',
    btnHover: 'rgba(255, 255, 255, 0.11)',
    btnActive: 'rgba(255, 255, 255, 0.05)',
  },
  facebook: '#3b5998',
};

const colorStyles = {
  gradient: {
    gold: {
      background: 'linear-gradient(to bottom, #f5ede4, #fff)',
    },
    blue: {
      background: 'linear-gradient(to bottom, #f5f9fc, #fff)',
    },
  },
  card: {
    s: {
      backgroundColor: '#fff',
      boxShadow: '0 40px 40px rgba(0, 0, 0, 0.03)',
    },
    m: {
      backgroundColor: '#fff',
      boxShadow: '0 60px 60px rgba(0, 0, 0, 0.03)',
    },
  },
  dropdown: {
    backgroundColor: '#fff',
    boxShadow: '0 16px 30px rgba(39, 43, 46, 0.1)',
  },
};

const fonts = {
  body: '"Open Sans", sans-serif',
  headings: '"Abril Fatface", serif',
};

const space = {
  xxl: 48,
  xl: 40,
  l: 32,
  m: 24,
  s: 16,
  xs: 8,
};

const fontSizes = {
  xl: 19,
  l: 17,
  m: 16,
  s: 15,
  xs: 14,
  xxs: 11,
  heading: {
    hero: 64,
    h1: 56,
    h2: 48,
    h3: 40,
    h4: 36,
    h5: 32,
    h6: 32,
    mobile: {
      default: 28,
    },
  },
  bodyHeading: {
    xl: 28,
    l: 26,
    m: 22,
    s: 20,
    xs: 18,
  },
};

const lineHeights = {
  xxs: 1.4545,
  xs: 1.5,
  s: 1.6,
  m: 1.625,
  l: 1.647,
  xl: 2,
  heading: {
    hero: 1.288,
    h1: 1.285,
    h2: 1.208,
    h3: 1.3,
    h4: 1.333,
    h5: 1.333,
    h6: 1.333,
    mobile: {
      default: 1.333,
    },
  },
  bodyHeading: {
    xl: 1.3,
    l: 1.3,
    m: 1.35,
    s: 1.4,
    xs: 1.4,
  },
};

const breakpointsObject: any = {
  s: '768px',
  m: '992px',
  l: '1160px',
};

const maxWidths = breakpointsObject;
const breakpoints: any = Object.values(breakpointsObject);

breakpoints.s = maxWidths.s;
breakpoints.m = maxWidths.m;
breakpoints.l = maxWidths.l;

export {
  colors,
  fonts,
  fontSizes,
  lineHeights,
  breakpoints,
  space,
  colorStyles,
  breakpointsObject,
  maxWidths,
  themeGet,
  styledSpaces as spaces,
};
