import gql from 'graphql-tag';

export default gql`
  mutation createFacebookMember(
    $token: String
    $cityId: Int
    $promoCode: String
  ) {
    createFacebookMember(
      token: $token
      cityId: $cityId
      promoCode: $promoCode
    ) {
      token
      member {
        id
        firstName
        email
        surname
        avatarURL
        termsAndPrivacy
        contactPermission
        city {
          id
          menuTitle
          slug
        }
        upcomingBookings {
          edges {
            node {
              restaurant {
                id
              }
              availability {
                id
                session
              }
              date
              time
              status
            }
          }
        }
      }
    }
  }
`;
