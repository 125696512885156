import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  color,
  space,
  display,
  width,
  height,
  ColorProps,
  SpaceProps,
  HeightProps,
  DisplayProps,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

type LinkStyles = {
  underline: boolean | string;
};

type LinkProps = {
  to?: any;
  href?: any;
  underline?: any;
  onClick?: () => void;
  external?: boolean;
  target?: string;
  tabIndex?: string;
  active?: boolean;
  NavComponent: any;
  children: any;
  as?: any;
  itemProp?: any;
  download?: boolean;
} & ColorProps &
  SpaceProps &
  HeightProps &
  DisplayProps;

export const styles = css<LinkStyles>`
  ${({ underline }) =>
    underline === 'true' ? 'border-bottom: 1px solid' : null};
  text-decoration: none;
  ${({ underline }) =>
    underline === 'true'
      ? css`
          &:hover {
            border-bottom-color: transparent;
          }
        `
      : null};
  cursor: pointer;
  outline: none;
  ${space};
  ${color};
  ${display};
  ${width};
  ${height};
  &:active {
    color: ${themeGet('colors.gold.900')};
  }
`;

const StyledLink = styled.a`
  ${styles};
  [active]
`;

const Link = ({
  to,
  href,
  underline,
  onClick,
  external,
  active,
  NavComponent,
  ...rest
}: LinkProps) => {
  const props = {
    underline: underline.toString(),
    ...rest,
  };
  if ((href && NavComponent) || (process.env.NEXT_PUBLIC_SITE_ID && to)) {
    return (
      <NavComponent href={to || href} passHref {...props}>
        <StyledLink {...props} className={active ? 'active' : ''} />
      </NavComponent>
    );
  }
  if (to) {
    const styledNavLinkProps = {
      to,
      getProps: ({ isCurrent }: { isCurrent: any }) => ({
        active: isCurrent ? isCurrent.toString() : null,
      }),
      ...props,
    };
    return <NavComponent {...styledNavLinkProps} />;
  }
  if (href) {
    return (
      <StyledLink
        target={external ? '_blank' : '_self'}
        // @ts-ignore
        rel={external ? 'noopener noreferrer nofollow' : null}
        href={href}
        as="a"
        active={active ? 'true' : null}
        {...props}
      />
    );
  }
  return <StyledLink role="button" as="span" onClick={onClick} {...props} />;
};

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  underline: PropTypes.bool,
  onClick: PropTypes.func,
  external: PropTypes.bool,
  NavComponent: PropTypes.any,
  // ...display.propTypes,
  // ...space.propTypes,
  // ...color.propTypes,
};

Link.defaultProps = {
  NavComponent: null,
  to: null,
  href: null,
  external: true, // whether to open link with target="_blank"
  underline: false,
  onClick: () => {},
};

export default Link;
