import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Icon from '../Icon';
import Box from '../Box';

const ExtenderStyled = styled(Box)`
  z-index: 1;
  position: relative;
  cursor: pointer;
  &::after {
    z-index: 2;
    content: " ";
    background: ${themeGet('colors.grayscale.300')};
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
  }
`;
const ExtenderRound = styled(Box)`
  z-index: 3;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  background: #fff;
  border: 1px solid ${themeGet('colors.grayscale.300')};
`;
const Gradient = styled(Box)`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 17px;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(0deg, #fff 20%, rgba(255, 255, 255, 0) 100%);
`;

const Extender = ({ isExpanded, gradient, ...props }) => {
  const iconName = `s-arrow-${isExpanded ? 'up' : 'down'}`;
  return (
    <ExtenderStyled {...props}>
      {!isExpanded && gradient && <Gradient />}
      <ExtenderRound>
        <Icon name={iconName} color="grayscale.600" />
      </ExtenderRound>
    </ExtenderStyled>
  );
};

Extender.propTypes = {
  isExpanded: PropTypes.bool,
  m: PropTypes.string,
  gradient: PropTypes.bool,
  ...space.propTypes,
};
Extender.defaultProps = {
  m: '5px 0 0',
  isExpanded: false,
  gradient: false,
};

export default Extender;
