import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import Box from '../Box';

const StyledList = styled(Box)`
  ${switchProp('pure', {
    1: css`
      padding-left: 0;
      list-style: none;
    `,
    0: css`
      padding-left: 25px;
    `,
  })};
  li {
    margin-bottom: ${({ itemspacing }) => (itemspacing ? `${itemspacing}px` : null)};
  } 
`;

const List = ({ pure, ...rest }) => <StyledList pure={pure ? 1 : 0} {...rest} />;

List.propTypes = {
  pure: PropTypes.bool,
  as: PropTypes.oneOf(['ul', 'ol']),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemspacing: PropTypes.number,
};
List.defaultProps = {
  m: 0,
  as: 'ul',
  pure: false,
  itemspacing: 5,
};

export default List;
