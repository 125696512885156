import React from 'react';
// import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Field } from 'formik';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { compareValues } from '@firsttable/functions';

const RegionField = (props) => {
  const { regionList } = useStaticQuery(graphql`
    query regionList {
      regionList: allCity(filter: { parentId: { eq: 0 } }) {
        edges {
          node {
            value: foreignId
            label: menuTitle
          }
        }
      }
    }
  `);
  const regionOptions = regionList.edges.map(({ node }) => ({ ...node }));
  return (
    <Field
      component={FormField}
      mb="15px"
      id="CityId"
      name="cityId"
      placeholder="Select a Region"
      size="l"
      type="select"
      options={regionOptions.sort(compareValues('label'))}
      {...props}
    />
  );
};

RegionField.propTypes = {};
RegionField.defaultProps = {};

export default RegionField;
