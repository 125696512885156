import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import Box from '../Box';

const StyledNumber = styled(Box)`
  background-image: -moz-linear-gradient(180deg, ${themeGet('colors.grayscale.300')} 30%, #fff 100%);
  background-image: -webkit-linear-gradient(180deg, ${themeGet('colors.grayscale.300')} 30%, #fff 100%);
  background-image: linear-gradient(180deg, ${themeGet('colors.grayscale.300')} 30%, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
`;

const Number = ({ num, ...props }) => (
  <StyledNumber {...props}>{num}</StyledNumber>
);

Number.propTypes = {
  num: PropTypes.number,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
};

Number.defaultProps = {
  fontFamily: 'headings',
  fontSize: '157px',
};

Number.displayName = 'Number';

export default Number;
