import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isBrowser } from '@firsttable/functions';
import { redirectPath } from '../apollo/cache';

const withRedirectPath = (ComponentToWrap: any) => {
  const WithAuthContext = (props: any) => {
    let redirectPathFromCache = null;
    if (isBrowser) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      redirectPathFromCache = useReactiveVar(redirectPath);
    }
    return (
      <ComponentToWrap
        redirectPath={redirectPathFromCache}
        updateRedirect={redirectPath}
        {...props}
      />
    );
  };
  WithAuthContext.displayName = 'withRedirectPath';

  return WithAuthContext;
};

export default withRedirectPath;
