import React from 'react';
import useBackUrl from '../hooks/backUrl';

const withBackUrl = (ComponentToWrap) => (props) => {
  const { backUrl, setBackUrl } = useBackUrl();
  return (
    <ComponentToWrap backUrl={backUrl} setBackUrl={setBackUrl} {...props} />
  );
};

export default withBackUrl;
