import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { PositionProps, SpaceProps } from 'styled-system';
import Box from '../Box';

const StyledLabelNew = styled(Box)`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
  margin-right: 15px;
  text-transform: uppercase;
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${themeGet('colors.white')};
  background: ${themeGet('colors.brand.red')};
  border-radius: 2px;
  font-size: ${themeGet('fontSizes.xxs')}px;
  font-weight: bold;
  padding: 4px 11px;
`;

const LabelNew = (props: PositionProps & SpaceProps) => (
  <StyledLabelNew {...props} />
);

LabelNew.propTypes = {
  alignSelf: PropTypes.string,
  children: PropTypes.any,
};
LabelNew.defaultProps = {
  alignSelf: 'center',
  children: 'new',
};

export default LabelNew;
