import React from 'react';
import PropTypes from 'prop-types';
import Text from '@firsttable/web-components/atoms/Text';
import { Field } from 'formik';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';


const ContactPermissionField = ({ name, id }) => (
  <>
    <Text fontSize="s">
      We&rsquo;d love to email you free booking credit coupons and updates when new
      {' '}
      restaurants join First Table in your city. Also, importantly, we&rsquo;d like
      {' '}
      to email you your reservation confirmation when you make bookings.
      {' '}
      We&rsquo;ll treat your personal details with the utmost care and will never
      {' '}
      sell them to other companies for marketing purposes. You will be able to
      {' '}
      withdraw this consent at any time under your profile.
    </Text>
    <Field
      component={FormField}
      type="radio"
      name={name}
      id={id || name}
      label="Contact permission"
      options={[
        { inputLabel: 'Yes please', inputValue: '1' },
        { inputLabel: 'No thanks (not recommended)', inputValue: '2' },
      ]}
    />
  </>
);

ContactPermissionField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

ContactPermissionField.defaultProps = {
  id: null,
};

export default ContactPermissionField;
