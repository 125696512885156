import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

import {
  space,
  SpaceProps,
  width,
  fontSize,
  color,
  ColorProps,
  TextColorProps,
  fontFamily,
  textAlign,
  TextAlignProps,
  lineHeight,
  fontWeight,
  fontStyle,
  FontWeightProps,
  letterSpacing,
  // typography,
  TypographyProps,
  display,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  verticalAlign,
  // layout,
  LayoutProps,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flexGrow,
  flex,
  alignContent,
  justifyItems,
  justifySelf,
  alignSelf,
  order,
  flexBasis,
  // flexbox,
  FlexboxProps,
  gridGap,
  gridRowGap,
  gridColumnGap,
  gridColumn,
  gridRow,
  gridArea,
  gridAutoFlow,
  gridAutoRows,
  gridAutoColumns,
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas,
  // grid,
  GridProps,
  background,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  BackgroundProps,
  borderRadius,
  borderColor,
  borders,
  BordersProps,
  boxShadow,
  opacity,
  overflow,
  position,
  PositionProps,
  zIndex,
  top,
  right,
  bottom,
  left,
  textStyle,
  colorStyle,
  ColorStyleProps,
  buttonStyle,
  // border,
  BorderProps,
  MarginProps,
  // padding,
  PaddingProps,
  // style,
  // variant,
  // styles,
  // Theme,
  // layout,
  // shadow,
  ShadowProps,
  SizeProps,
} from 'styled-system';

const StyledBox = styled.div`
  ${space};
  ${width};
  ${fontSize};
  ${color};
  ${fontFamily};
  ${textAlign};
  ${lineHeight};
  ${fontWeight};
  ${fontStyle};
  ${letterSpacing};
  ${display};
  ${maxWidth};
  ${minWidth};
  ${height};
  ${maxHeight};
  ${minHeight};
  ${verticalAlign};
  ${alignItems};
  ${justifyContent};
  ${flexWrap};
  ${flexDirection};
  ${flexGrow};
  ${flex};
  ${alignContent};
  ${justifyItems};
  ${justifySelf};
  ${alignSelf};
  ${order};
  ${flexBasis};
  ${gridGap};
  ${gridRowGap};
  ${gridColumnGap};
  ${gridColumn};
  ${gridRow};
  ${gridArea};
  ${gridAutoFlow};
  ${gridAutoRows};
  ${gridAutoColumns};
  ${gridTemplateRows};
  ${gridTemplateColumns};
  ${gridTemplateAreas};
  ${background};
  ${backgroundImage};
  ${backgroundSize};
  ${backgroundPosition};
  ${backgroundRepeat};
  ${borderRadius};
  ${borders};
  ${borderColor};
  ${boxShadow};
  ${opacity};
  ${overflow};
  ${position};
  ${zIndex};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${textStyle};
  ${colorStyle};
  ${buttonStyle};
`;

// see here: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/styled-system/styled-system-tests.tsx

type OtherProps = {
  gap?: any;
  display?: any;
  options?: any;
  onClick?: any;
  children?: any;
  as?: any;
  whiteSpace?: string;
  style?: CSSProperties;
};

export interface BoxStyleProps
  extends MarginProps,
    PaddingProps,
    SpaceProps,
    LayoutProps,
    GridProps,
    FlexboxProps,
    SizeProps,
    PositionProps,
    BackgroundProps,
    BorderProps,
    BordersProps,
    ShadowProps,
    TypographyProps,
    ColorStyleProps,
    FontWeightProps,
    TextColorProps,
    TextAlignProps,
    ColorProps {}

export type BoxProps = BoxStyleProps & HTMLAttributes<any> & OtherProps;

const Box = forwardRef((props: BoxProps, ref) => (
  <StyledBox {...props} ref={ref} />
));
Box.displayName = 'Box';
// export const Grid: React.ComponentType<GridProps> = styled(grid);
// Box.propTypes = {}

export default Box;
