import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { fontSize as fs, lineHeight as lh } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { switchProp } from 'styled-tools';
import iconCheck from './icon.svg';
import Text from '../../atoms/Text';

const Label = styled.label`
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${fs};
  ${lh};
  user-select: none;
  ${switchProp('position', {
    left: css`
      padding-left: 30px;
    `,
    right: css`
      width: 100%;
      padding-right: 30px;
    `,
  })}
  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
  }
  input:checked ~ span {
    background: url('${iconCheck}') no-repeat center center;
    background-color: ${({ disabled }) =>
      disabled ? themeGet('colors.grayscale.300') : '#fff'};
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 1px;
  height: 20px;
  width: 20px;
  border-radius: 1px;
  background: ${({ disabled }) =>
    disabled ? themeGet('colors.grayscale.300') : '#fff'};
  border: 1px solid ${themeGet('colors.grayscale.400')};
  ${switchProp('position', {
    left: css`
      left: 0;
    `,
    right: css`
      right: 0;
    `,
  })}
`;

const Checkbox = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  disabled,
  fontSize,
  lineHeight,
  position,
  success,
  selected,
  error,
  isChanged,
  isUsed,
  checked,
  touched,
  ...props
}) => (
  <Label
    fontSize={fontSize}
    lineHeight={lineHeight}
    disabled={disabled}
    position={position}
  >
    {label}
    <input
      id={id}
      {...props}
      type="checkbox"
      name={name}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      checked={selected}
    />
    <Checkmark disabled={disabled} position={position} />
    {touched && error && (
      <Text color="error" fontSize="xs" mt="5px" mb="0">
        {error}
      </Text>
    )}
  </Label>
);

Checkbox.propTypes = {
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  success: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  position: PropTypes.oneOf(['left', 'right']),
  isChanged: PropTypes.bool,
  isUsed: PropTypes.bool,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.bool,
};
Checkbox.defaultProps = {
  id: '',
  name: '',
  fontSize: 's',
  lineHeight: 'xxs',
  label: '',
  success: '',
  error: '',
  position: 'left',
  isChanged: false,
  isUsed: false,
  checked: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  selected: '',
  value: '',
  touched: false,
};

export default Checkbox;
