import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

const ModalContext = createContext({
  options: {
    type: 'none',
    data: [],
  },
  showModal: false,
  showModalFunc: () => {},
  hideModalFunc: () => {},
  isModalOpen: false,
});

export class ModalProvider extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentWillUnmount() {
    // clear timeout if we have used it with a modal
    if (this.showModalTimeOut) {
      clearTimeout(this.showModalTimeOut);
    }
  }

  showModal = (modal, options) => {
    // eslint-disable-line react/sort-comp
    const modalOptions = { ...options };
    delete modalOptions.timeout;

    if (options?.timeout) {
      this.showModalTimeOut = setTimeout(
        () =>
          this.setState({
            modal, // eslint-disable-line react/no-unused-state
            showModal: true,
            options: modalOptions,
          }),
        options.timeout,
      );
    } else {
      this.setState({
        modal, // eslint-disable-line react/no-unused-state
        showModal: true, // eslint-disable-line react/no-unused-state
        options: modalOptions,
      });
    }
  };

  hideModal = (event) => {
    const { options } = this.state;
    if (
      event &&
      event.type === 'keydown' &&
      event.keyCode === 27 &&
      options.disableEscExit
    )
      return;
    this.setState({
      modalType: 'none', // eslint-disable-line react/no-unused-state
      showModal: false, // eslint-disable-line react/no-unused-state
    });
  };

  isModalOpen = () => {
    const { showModal } = this.state;
    return showModal;
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    showModal: false, // eslint-disable-line react/no-unused-state
  };

  render() {
    const { children } = this.props;
    const { showModal } = this.state;
    const contextValue = {
      data: this.state,
      isModalOpen: showModal,
      showModalFunc: this.showModal,
      hideModalFunc: this.hideModal,
    };

    return (
      <ModalContext.Provider value={contextValue}>
        {children}
      </ModalContext.Provider>
    );
  }
}

export default ModalContext;
export const ModalConsumer = ModalContext.Consumer;
