import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, space, SizeProps, SpaceProps } from 'styled-system';

const StyledDots = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  ${space};
  ${color};
`;

type DotPropsType = {
  size: number;
};

const Dot = styled.span<DotPropsType>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 0 ${({ size }) => size / 2}px;
  border-radius: 50%;
  background: currentColor;
  animation-name: dots;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-delay: 0.15s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
`;

type DotProps = SizeProps & SpaceProps & { size: any };

const Dots = ({ size, ...rest }: DotProps) => (
  <StyledDots {...rest}>
    <Dot size={size} />
    <Dot size={size} />
    <Dot size={size} />
  </StyledDots>
);

Dots.propTypes = {
  mx: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

Dots.defaultProps = {
  color: 'brand.blue',
  size: 6,
  mx: 'auto',
};

export default Dots;
