import React from 'react';
import styled from 'styled-components';
import {
  width,
  maxWidth as styledMaxWidth,
  display,
  position,
} from 'styled-system';
import PropTypes from 'prop-types';
import { themeGet } from '@firsttable/web-theme';

const Img = styled.img`
  ${width};
  ${styledMaxWidth};
  ${display};
  ${position};
`;

const Image = ({
  src,
  alt,
  fluid,
  aspectRatio,
  ...rest
}: {
  src: string;
  alt: string;
  fluid?: string;
  aspectRatio?: any;
  maxWidth?: any;
}) => {
  const { maxWidth } = rest;
  if (aspectRatio) {
    return (
      <div
        style={{
          display: 'inline-block',
          width: '100%',
          maxWidth: typeof maxWidth === 'string' ? maxWidth : '',
        }}
      >
        <div
          style={{
            paddingBottom: `${100 / aspectRatio}%`,
            background: themeGet('colors.grayscale.300'),
          }}
        />
      </div>
    );
  }
  // @ts-ignore
  return <Img src={src} alt={alt} maxWidth={fluid && '100%'} {...rest} />;
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fluid: PropTypes.bool,
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
};

Image.defaultProps = {
  src: null,
  fluid: true,
  alt: '',
};

Image.displayName = 'Image';

export default Image;
