import gql from 'graphql-tag';

export default gql`
  query getLiveUser {
    user: Member {
      id
      firstName
      surname
      email
      avatarURL
      noFacebook
      noInstagram
      contactPermission
      city {
        id
        menuTitle
        slug
      }
    }
  }
`;
