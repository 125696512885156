import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from '@firsttable/web-theme';
import Box, { BoxStyleProps } from '../Box';

const StyledContainer = styled(Box)`
  position: relative;
  box-sizing: content-box;
`;

const Container = (props: BoxStyleProps) => <StyledContainer {...props} />;

Container.propTypes = {
  pl: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  pr: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  mx: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  maxWidth: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
};

Container.defaultProps = {
  pl: ['s', 'm'],
  pr: ['s', 'm'],
  mx: 'auto',
  maxWidth: breakpoints.l,
};

export default Container;
