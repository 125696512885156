import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { navigate } from 'gatsby';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import { errorMessage, signUpEvent } from '@firsttable/functions';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import Cookies from 'js-cookie';
import RegionField from './Fields/RegionField';
import withLocation from '../../../hocs/location';
import ContactPermissionField from './Fields/ContactPermissionField';
import TermsAndConditionsField from './Fields/TermsAndConditionsField';

const UPDATE_USER_TERMS = gql`
  mutation updateUser($userInput: MemberInput!, $cityId: Int, $siteId: Int!) {
    updateUser(userInput: $userInput, cityId: $cityId, siteId: $siteId) {
      id
      city {
        id
        menuTitle
        slug
      }
    }
  }
`;

const MoreInfoForm = (props) => (
  <FormLayout {...props} actionLabel="Continue to First Table">
    <Field
      component={FormField}
      mb="25px"
      type="email"
      name="email"
      id="Email"
      label="Please check your email is correct"
    />
    <RegionField label="Please select a region closest to you" />
    <TermsAndConditionsField name="termsAndPrivacy" id="Terms" />
    <ContactPermissionField name="contactPermission" id="ContactPermission" />
  </FormLayout>
);

MoreInfoForm.defaultProps = {};

MoreInfoForm.propTypes = {
  status: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default compose(
  withLocation,
  graphql(UPDATE_USER_TERMS, { name: 'updateUser' }),
  withFormik({
    mapPropsToValues: ({ userData }) => ({
      email: userData.email,
      termsAndPrivacy: false,
      contactPermission: '1',
      cityId: +userData?.city?.id || '',
    }),
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        cityId: Yup.string().required('Required'),
        termsAndPrivacy: Yup.boolean()
          .oneOf([true], 'Must Accept Terms and Conditions')
          .required(),
      }),
    handleSubmit: async (
      values,
      { setStatus, setSubmitting, props: { updateUser, backUrl } },
    ) => {
      const permission = values.contactPermission === '1';

      const { error, data } = await updateUser({
        variables: {
          userInput: {
            email: values.email,
            contactPermission: permission,
            termsAndPrivacy: values.termsAndPrivacy,
          },
          cityId: +values.cityId || 0,
          siteId: +process.env.GATSBY_SITE_ID,
        },
      }).catch((e) => {
        setStatus({ message: errorMessage(e), type: 'danger' });
        setSubmitting(false);
        return false;
      });
      if (error) {
        setStatus({ message: errorMessage(error), type: 'danger' });
        setSubmitting(false);
        return false;
      }

      const cityURL = data.updateUser.city ? data.updateUser.city.slug : null;
      const region = data.updateUser.city
        ? data.updateUser.city.menuTitle
        : null;

      signUpEvent({
        loginMethod: 'facebook',
        member: data.updateUser,
        permissions: permission ? 'TRUE' : 'FALSE',
        region,
      });

      const returnURL =
        backUrl && backUrl !== '/' ? backUrl : cityURL || '/auth/complete/';
      Cookies.set('userSignedUp', true);
      await navigate(returnURL);
      setSubmitting(false);
      return true;
    },
  }),
)(MoreInfoForm);
