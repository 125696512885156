import gql from 'graphql-tag';

export default gql`
  mutation createFullPriceBooking(
    $restaurantId: Int!
    $date: String!
    $time: String!
    $covers: Int!
  ) {
    createFullPriceBooking(
      restaurantId: $restaurantId
      date: $date
      time: $time
      people: $covers
    ) {
      reference
    }
  }
`;
