import React, { FC } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, display } from 'styled-system';

type StyledFormProps = {
  inline: number;
};
const StyledForm = styled.form<StyledFormProps>`
  ${display};
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
  ${space};
`;

interface FormProps {
  onSubmit: () => void;
  inline?: boolean;
  status?: any;
  children?: any;
  display?: string;
}
const Form: FC<FormProps> = ({
  onSubmit,
  status,
  children,
  inline,
  ...props
}) => (
  <StyledForm onSubmit={onSubmit} inline={inline ? 1 : 0} {...props}>
    {children}
  </StyledForm>
);

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  children: PropTypes.any,
  inline: PropTypes.bool,
  display: PropTypes.string,
};
Form.defaultProps = {
  status: {},
  inline: false,
  display: 'flex',
};

export default Form;
