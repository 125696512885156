import gql from 'graphql-tag';

export default gql`
  mutation createMember(
    $email: String!
    $firstName: String!
    $surname: String!
    $password: String
    $promoCode: String
    $cityId: Int
    $termsAndPrivacy: Boolean!
    $contactPermission: Boolean!
    $siteId: Int!
  ) {
    createMember(
      email: $email
      firstName: $firstName
      surname: $surname
      password: $password
      promoCode: $promoCode
      cityId: $cityId
      termsAndPrivacy: $termsAndPrivacy
      contactPermission: $contactPermission
      siteId: $siteId
    ) {
      id
      token
      firstName
      surname
      email
      avatarURL
      noFacebook
      noInstagram
      contactPermission
      city {
        menuTitle
        slug
      }
    }
  }
`;
