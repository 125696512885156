import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box from '../Box';

const StyledTable = styled(Box)`
  width: 100%;
  border-collapse: collapse;
`;
const Table = (props) => <StyledTable {...props} display={[null, 'table']} />;

const TBody = (props) => <Box {...props} display={[null, 'table-row-group']} />;

const THead = (props) => (
  <Box {...props} display={['none', 'table-row-group']} />
);

const Tr = (props) => (
  <Box {...props} display={[null, 'table-row']} mb={['16px', 0]} />
);

const StyledTh = styled(Box)`
  border: 1px solid ${themeGet('colors.grayscale.300')};
  font-weight: 600;
  vertical-align: middle;
`;
const Th = (props) => (
  <StyledTh
    {...props}
    display={[null, 'table-cell']}
    p="10px"
    mt={['-1px', 0]}
  />
);

const StyledTd = styled(Box)`
  border: 1px solid ${themeGet('colors.grayscale.300')};
  vertical-align: middle;
`;
const Td = (props) => (
  <StyledTd
    {...props}
    display={[null, 'table-cell']}
    p="5px 10px"
    mt={['-1px', 0]}
  />
);

export { Table, TBody, THead, Tr, Th, Td };
