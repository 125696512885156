"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupBy = exports.formatFacebookUrl = exports.formatInstagramUrl = exports.formatUrl = exports.formatUrlTitle = exports.httpsFormattedURL = exports.isFirstCharacterAVowel = exports.capitalise = exports.numericToWord = exports.decodeEntities = exports.scrollTo = exports.numberOfDaysToShow = exports.getUsersPosition = exports.range = exports.compareValues = exports.availabilityDaysToShow = exports.slugify = exports.isBrowser = void 0;
var react_scroll_1 = require("react-scroll");
var user_1 = require("./user");
exports.isBrowser = (function () { return typeof window !== 'undefined'; })();
var slugify = function (string) {
    var a = 'àáäâãåăæąçćčđèéėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
    var b = 'aaaaaaaaacccdeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------';
    var p = new RegExp(a.split('').join('|'), 'g');
    return (string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, function (c) { return b.charAt(a.indexOf(c)); }) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '')); // Trim - from end of text
};
exports.slugify = slugify;
var capitalise = function (s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
};
exports.capitalise = capitalise;
var isFirstCharacterAVowel = function (c) {
    return ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase().charAt(0)) !== -1;
};
exports.isFirstCharacterAVowel = isFirstCharacterAVowel;
var getUsersPosition = function (options) {
    if (options === void 0) { options = {}; }
    return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};
exports.getUsersPosition = getUsersPosition;
/**
 * range()
 * @see https://gist.githubusercontent.com/gladchinda/439981b34aa8f23c661e9663edf762f0/raw/96a4f5f25539ae9a871264f073e849580c160b31/range-function.js
 *
 * Returns an array of numbers between a start number and an end number incremented
 * sequentially by a fixed number(step), beginning with either the start number or
 * the end number depending on which is greater.
 *
 * @param {number} start (Required: The start number.)
 * @param {number} end (Required: The end number. If end is less than start,
 *  then the range begins with end instead of start and decrements instead of increment.)
 * @param {number} step (Optional: The fixed increment or decrement step. Defaults to 1.)
 *
 * @return {array} (An array containing the range numbers.)
 *
 * @throws {TypeError} (If any of start, end and step is not a finite number.)
 * @throws {Error} (If step is not a positive number.)
 */
var range = function (start, end, step) {
    if (step === void 0) { step = 1; }
    // Test that the first 3 arguments are finite numbers.
    // Using Array.prototype.every() and Number.isFinite().
    var allNumbers = [start, end, step].every(Number.isFinite);
    // Throw an error if any of the first 3 arguments is not a finite number.
    if (!allNumbers) {
        throw new TypeError('range() expects only finite numbers as arguments.');
    }
    // Ensure the step is always a positive number.
    if (step <= 0) {
        throw new Error('step must be a number greater than 0.');
    }
    // When the start number is greater than the end number,
    // modify the step for decrementing instead of incrementing.
    if (start > end) {
        // eslint-disable-next-line no-param-reassign
        step = -step;
    }
    // Determine the length of the array to be returned.
    // The length is incremented by 1 after Math.floor().
    // This ensures that the end number is listed if it falls within the range.
    var length = Math.floor(Math.abs((end - start) / step)) + 1;
    // Fill up a new array with the range numbers
    // using Array.from() with a mapping function.
    // Finally, return the new array.
    // @ts-ignore
    return Array.from(Array(length), function (x, index) { return start + index * step; });
};
exports.range = range;
var numericToWord = function (value) {
    var input = String(value).split('');
    var mapData = {
        0: 'zero',
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
        5: 'five',
        6: 'six',
        7: 'seven',
        8: 'eight',
        9: 'nine',
    };
    var tempArray = [];
    for (var i = 0; i < input.length; i += 1) {
        tempArray.push(mapData[input[i]]);
    }
    return tempArray.join(' ');
};
exports.numericToWord = numericToWord;
/**
 * From https://stackoverflow.com/questions/44195322/a-plain-javascript-way-to-decode-html-entities-works-on-both-browsers-and-node
 * @param encodedString
 * @returns {*}
 */
var decodeEntities = function (encodedString) {
    var translateRe = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
    };
    // @ts-ignore
    return encodedString
        .replace(translateRe, function (match, entity) { return translate[entity]; })
        .replace(/&#(\d+);/gi, function (match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
};
exports.decodeEntities = decodeEntities;
// @ts-ignore
var scrollTo = function (anchor, offset, duration) {
    if (offset === void 0) { offset = 0; }
    if (duration === void 0) { duration = 900; }
    react_scroll_1.scroller.scrollTo(anchor, {
        duration: duration,
        smooth: 'easeInOutCubic',
        isDynamic: true,
        offset: offset,
    });
};
exports.scrollTo = scrollTo;
var compareValues = function (key, order) {
    if (order === void 0) { order = 'asc'; }
    return function (a, b) {
        // eslint-disable-next-line no-prototype-builtins
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }
        var varA = a[key];
        var varB = b[key];
        var comparison = 0;
        if (varA > varB) {
            comparison = 1;
        }
        else if (varA < varB) {
            comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
    };
};
exports.compareValues = compareValues;
var numberOfDaysToShow = (0, user_1.howManyDaysOfAvailabilityToShow)();
exports.numberOfDaysToShow = numberOfDaysToShow;
// handle 9pm availability
var availabilityDaysToShow = function (availability, siteId) {
    var currenthourOfDay = new Date().getHours();
    var currentMinutes = new Date().getMinutes();
    var minuteCutOff = 0; // 0
    var hourCutOff = 21; // 9pm
    var daysToShow = Object.keys(availability).slice(0, numberOfDaysToShow);
    if (siteId &&
        [1].indexOf(siteId) !== -1 && // check it's in the right region e.g. Queenstown
        currenthourOfDay >= hourCutOff && // check the current hour is greater than or equal to the roll over time
        currentMinutes >= minuteCutOff && // check the current minute is greater than or equal to the roll over time
        Object.keys(availability).length > 7 // we have the length to slice
    ) {
        // slice the first day off
        daysToShow = Object.keys(availability).slice(1, numberOfDaysToShow + 1);
    }
    return daysToShow;
};
exports.availabilityDaysToShow = availabilityDaysToShow;
var httpsFormattedURL = function (u) {
    var s = u;
    if (!s.match(/^[a-zA-Z]+:\/\//)) {
        s = "https://" + s;
    }
    return s;
};
exports.httpsFormattedURL = httpsFormattedURL;
var formatUrlTitle = function (url) {
    return url.replace(/https:\/\/|http:\/\/|www./gi, '');
};
exports.formatUrlTitle = formatUrlTitle;
var formatUrl = function (url) {
    var formattedUrl = url;
    if (!/^https?:\/\//i.test(formattedUrl)) {
        formattedUrl = "http://" + formattedUrl;
    }
    return formattedUrl;
};
exports.formatUrl = formatUrl;
var formatInstagramUrl = function (url) {
    var newUrl = url.replace('@', 'https://www.instagram.com/');
    if (newUrl.indexOf('instagram.com') === -1) {
        newUrl = "https://www.instagram.com/" + newUrl;
    }
    return formatUrl(newUrl);
};
exports.formatInstagramUrl = formatInstagramUrl;
var formatFacebookUrl = function (url) {
    var newUrl = url.replace('@', 'https://www.facebook.com/');
    if (newUrl.indexOf('facebook.com') === -1) {
        newUrl = "https://www.facebook.com/" + newUrl;
    }
    return formatUrl(newUrl);
};
exports.formatFacebookUrl = formatFacebookUrl;
/**
 * Function to group array of objects into subsets
 * Takes a function to resolve the key.
 * Callback function can return a string, or an array of key strings if you want the object to appear in more than one group
 * e.g groupBy(jsonObj, (i)=>i.age > 17 ? 'adult' : 'child'); or groupBy(posts, (i)=>i.author.split(','));
 *
 * @param array original list of objects
 * @param func resolver function, should return string or array of string for grouping key
 * @returns returns an object (key:array) of grouped items
 */
var groupBy = function (array, func) {
    return __spreadArray([], array, true).reduce(function (groups, item) {
        var groupId = func(item); // get grouping key(s)
        var loop = Array.isArray(groupId) ? groupId : [groupId]; // always run keys as an array
        loop.forEach(function (name) {
            var group = groups[name] || []; // find or create group
            group.push(item); // add item to group
            groups[name] = group; // push in altered group
        });
        return groups;
    }, {});
};
exports.groupBy = groupBy;
