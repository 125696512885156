import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSize as fs, lineHeight as lh } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Text from '../../atoms/Text';

const Label = styled.label`
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${fs};
  ${lh};
  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
  }
  input:checked ~ span {
    background: ${({ disabled }) =>
      disabled ? themeGet('colors.grayscale.300') : '#fff'};
    &::before {
      display: block;
    }
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  display: flex;
  border-radius: 50%;
  background: ${({ disabled }) =>
    disabled ? themeGet('colors.grayscale.300') : '#fff'};
  border: 1px solid ${themeGet('colors.grayscale.400')};
  &::before {
    display: none;
    content: ' ';
    width: 8px;
    height: 8px;
    margin: auto;
    border-radius: 50%;
    background: ${themeGet('colors.brand.blue')};
  }
`;

const RadioButton = forwardRef(
  (
    {
      id,
      label,
      disabled,
      fontSize,
      lineHeight,
      name,
      value,
      onChange,
      onBlur,
      selected,
      touched,
      description,
      ...props
    },
    ref
  ) => (
    <Label fontSize={fontSize} lineHeight={lineHeight} disabled={disabled}>
      {label}
      <input
        id={id}
        name={name}
        type="radio"
        {...props}
        value={value} // could be something else for output?
        checked={selected}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        ref={ref}
      />
      {description && <Text>{description}</Text>}
      <Checkmark disabled={disabled} />
    </Label>
  )
);

RadioButton.displayName = 'RadioButton';
RadioButton.propTypes = {
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
RadioButton.defaultProps = {
  fontSize: 's',
  lineHeight: 'xxs',
  disabled: false,
  touched: false,
  id: null,
  label: '',
  description: '',
  onChange: () => {},
  onBlur: () => {},
  value: '',
  selected: '',
};

export default RadioButton;
