import at from 'lodash.at';
import * as theme from './index';

const themeGet = (path: any, fallback?: any) => {
  const value = at(theme, path)[0];
  if (value) {
    return value;
  }

  if (fallback === undefined) {
    console.warn(`"${path}" doesn't exist.`);
    return null;
  }

  return fallback;
};

export default themeGet;
