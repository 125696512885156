import gql from 'graphql-tag';

export default gql`
  mutation createMemberToken($email: String!, $password: String!) {
    createToken(Email: $email, Password: $password) {
      token
      member {
        id
        firstName
        email
        surname
        avatarURL
        termsAndPrivacy
        contactPermission
        city {
          id
          menuTitle
          slug
        }
        upcomingBookings {
          edges {
            node {
              restaurant {
                id
              }
              availability {
                id
                session
              }
              date
              time
              status
            }
          }
        }
      }
    }
  }
`;
