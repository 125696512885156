import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

const Flex = (props) => <Box {...props} />;

Flex.propTypes = {
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ...Box.propTypes,
};
Flex.defaultProps = {
  display: 'flex',
};
Flex.displayName = 'Flex';

export default Flex;
