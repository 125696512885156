import { navigate } from 'gatsby';
import { setUser } from '@firsttable/functions';
import { gql } from '@apollo/client';

const LOG_OUT = gql`
  mutation logOutMember {
    logOut {
      status
      message
    }
  }
`;

const logOutUser = async (client, location = {}) => {
  // log out of API, remove JWTs and remove active bookings
  await client.mutate({ mutation: LOG_OUT }).catch((e) => e);
  //
  // // remove user from local store
  setUser({});
  client.onResetStore(async () => {
    const re = '/(profile|review|gift-vouchers|booking|invite)/';
    if (location.pathname.match(re)) {
      let redirectPath = location.pathname;
      // ensure redirect path doesn't have an auth url
      if (redirectPath.includes('auth')) {
        redirectPath = '/';
      }

      await navigate(
        `/auth/sign-in/?redirected=1&backUrl=${encodeURIComponent(
          redirectPath,
        )}`,
      );
      return;
    }
    if (!location.pathname.match(re)) {
      await navigate(
        location.pathname ? `${location.pathname}?redirected=1` : '/',
      );
    }
  });
  // // reset apollo client store to defaults
  await client.resetStore();
};

export { logOutUser };
