"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.impressionEvent = exports.removeCartEvent = exports.purchaseEvent = exports.checkoutStepEvent = exports.productDetailEvent = exports.loggedInStatusEvent = exports.signUpEvent = exports.setUserEvent = exports.formOpenEvent = exports.findATableEvent = void 0;
var isBrowser = function () { return typeof window !== "undefined"; };
var track = function (event) {
    if (isBrowser()) {
        var dataLayer = window.dataLayer || [];
        // console.log('GTA Event', event);
        dataLayer.push(event);
    }
};
// events
var findATableEvent = function (_a) {
    var values = _a.values, city = _a.city;
    if (values) {
        track({
            event: 'findATableCTA',
            eventDetails: {
                category: 'Homepage',
                action: 'Find a Table',
                label: values.selectedDate + "|" + city + "|" + values.session,
            },
        });
    }
};
exports.findATableEvent = findATableEvent;
var formOpenEvent = function (_a) {
    var action = _a.action, label = _a.label;
    return track({
        event: 'formOpen',
        eventDetails: {
            category: 'Forms',
            action: action,
            label: label,
        },
    });
};
exports.formOpenEvent = formOpenEvent;
var setUserEvent = function (user) { return track({
    event: 'userAttributeSet',
    userId: (user === null || user === void 0 ? void 0 : user.id) ? user.id : '',
    loggedInStatus: (user === null || user === void 0 ? void 0 : user.isLoggedIn) ? 'TRUE' : 'FALSE',
}); };
exports.setUserEvent = setUserEvent;
// auth
var signUpEvent = function (_a) {
    var loginMethod = _a.loginMethod, member = _a.member, permissions = _a.permissions, region = _a.region;
    if (member && permissions && region) {
        track({
            event: 'signupSuccess',
            userId: member.id,
            loggedInStatus: 'True',
            newsletterSignup: permissions,
            loginMethod: loginMethod,
            region: region,
            eventDetails: {
                category: 'Forms',
                action: 'Sign-up Form - Success',
                label: "email|" + permissions + "|" + region,
            },
        });
    }
};
exports.signUpEvent = signUpEvent;
var loggedInStatusEvent = function (_a) {
    var event = _a.event, userId = _a.userId, category = _a.category, loggedInStatus = _a.loggedInStatus;
    track({
        event: event,
        userId: userId,
        loggedInStatus: loggedInStatus,
        eventDetails: {
            category: category,
            action: userId,
        },
    });
};
exports.loggedInStatusEvent = loggedInStatusEvent;
// enhanced ecommerce
var productDetailEvent = function (restaurant) {
    var _a;
    if (restaurant) {
        track({
            event: 'productDetail',
            eventDetails: {
                category: 'Enhanced Ecommerce',
                action: 'Product Detail',
                label: restaurant.foreignId,
            },
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: restaurant.title,
                            id: restaurant.foreignId,
                            price: restaurant.priceInNZD || 10,
                            metric2: cleanPrice(restaurant.bookingPrice),
                            brand: (_a = restaurant === null || restaurant === void 0 ? void 0 : restaurant.region) === null || _a === void 0 ? void 0 : _a.title,
                            category: 'Dinner',
                        },
                    ],
                },
            },
        });
    }
};
exports.productDetailEvent = productDetailEvent;
var checkoutStepEvent = function (booking) {
    var _a, _b;
    if (booking) {
        track({
            event: 'checkout',
            eventDetails: {
                category: 'Enhanced Ecommerce',
                action: 'Checkout Step 1',
            },
            ecommerce: {
                currencyCode: 'NZD',
                checkout: {
                    actionField: { step: 1 },
                    products: [
                        {
                            name: booking.restaurant.title,
                            id: booking.restaurant.id,
                            price: booking.restaurant.priceInNZD,
                            metric2: cleanPrice(booking.restaurant.bookingPrice),
                            brand: (_b = (_a = booking.restaurant) === null || _a === void 0 ? void 0 : _a.region) === null || _b === void 0 ? void 0 : _b.menuTitle,
                            category: booking.availability.session,
                            variant: booking.date + " " + booking.time,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    }
};
exports.checkoutStepEvent = checkoutStepEvent;
var purchaseEvent = function (booking, conversionData, siteID) {
    var _a;
    if (booking && conversionData && siteID) {
        track({
            event: 'purchase',
            newsletterSignup: (booking === null || booking === void 0 ? void 0 : booking.restaurantMarketingPermission)
                ? 'TRUE'
                : 'FALSE',
            eventDetails: {
                category: 'Enhanced Ecommerce',
                action: 'Purchase',
                label: booking === null || booking === void 0 ? void 0 : booking.reference,
            },
            ecommerce: {
                purchase: {
                    actionField: {
                        id: booking === null || booking === void 0 ? void 0 : booking.id,
                        revenue: booking === null || booking === void 0 ? void 0 : booking.priceInNZD,
                        tax: conversionData.Tax,
                        shipping: '0',
                        coupon: '',
                        option: '',
                        affiliation: siteID,
                    },
                    products: [
                        {
                            name: booking === null || booking === void 0 ? void 0 : booking.restaurant.menuTitle,
                            id: booking === null || booking === void 0 ? void 0 : booking.reference,
                            price: booking === null || booking === void 0 ? void 0 : booking.priceInNZD,
                            metric2: booking === null || booking === void 0 ? void 0 : booking.price,
                            // brand: convData.CityTitle, // Brand of restaurant for reservation e.g. ‘Auckland’ TODO add this as well
                            category: (_a = booking === null || booking === void 0 ? void 0 : booking.availability) === null || _a === void 0 ? void 0 : _a.session,
                            variant: booking.date + " " + booking.time,
                            coupon: '',
                            quantity: 1,
                            dimension4: booking.people,
                            dimension5: (booking === null || booking === void 0 ? void 0 : booking.restaurantMarketingPermission)
                                ? 'TRUE'
                                : 'FALSE',
                            dimension6: (booking === null || booking === void 0 ? void 0 : booking.hasReview) ? 'TRUE' : 'FALSE', // Is the user happy to leave a review e.g. ‘TRUE’
                        },
                    ],
                },
            },
        });
    }
};
exports.purchaseEvent = purchaseEvent;
var removeCartEvent = function (booking) {
    var _a, _b, _c, _d;
    if (booking) {
        track({
            event: 'removeFromCart',
            eventDetails: {
                category: 'Enhanced Ecommerce',
                action: 'Remove From Cart',
                label: booking.reference,
            },
            ecommerce: {
                currencyCode: booking.currency,
                remove: {
                    products: [
                        {
                            name: booking.restaurant.title,
                            id: booking.reference,
                            price: booking.restaurant.priceInNZD,
                            metric2: cleanPrice((_a = booking.restaurant) === null || _a === void 0 ? void 0 : _a.bookingPrice),
                            brand: (_c = (_b = booking.restaurant) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.menuTitle,
                            category: (_d = booking === null || booking === void 0 ? void 0 : booking.availability) === null || _d === void 0 ? void 0 : _d.session,
                            variant: '',
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    }
};
exports.removeCartEvent = removeCartEvent;
// track event for all pages
var impressionEvent = function (title, restaurants) {
    if (title && restaurants) {
        track({
            event: 'productImpressions',
            eventDetails: {
                category: 'Enhanced Ecommerce',
                action: 'Product Impressions',
                label: title,
            },
            ecommerce: {
                currencyCode: 'NZD ',
                impressions: restaurants.map(function (restaurant, i) {
                    var _a;
                    return ({
                        name: restaurant.title,
                        id: restaurant.foreignId,
                        price: restaurant.priceInNZD || 10,
                        metric2: cleanPrice(restaurant === null || restaurant === void 0 ? void 0 : restaurant.bookingPrice),
                        brand: (_a = restaurant === null || restaurant === void 0 ? void 0 : restaurant.region) === null || _a === void 0 ? void 0 : _a.menuTitle,
                        variant: 'Dinner',
                        list: title,
                        position: i + 1
                    });
                }),
            }
        });
    }
};
exports.impressionEvent = impressionEvent;
var cleanPrice = function (price) { return (price) ? +price.replace(/\D/g, '') : 10; };
