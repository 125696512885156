import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { style } from 'styled-system';
import { themeGet } from '@firsttable/web-theme';
import Box, { BoxStyleProps } from '../Box';

const marginLeft = style({
  prop: 'gap',
  cssProperty: 'marginLeft',
  transformValue(n) {
    if (n === null) return null;
    return -themeGet(`space.${n}`, n);
  },
});

const StyledRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  ${marginLeft};
`;

const Row = ({
  children,
  gap,
  ...props
}: {
  children: any;
  gap?: number | number[];
  textAlign?: any;
} & BoxStyleProps) => (
  <StyledRow gap={gap} {...props}>
    {React.Children.map(children, (child) => {
      if (child === null) {
        return false;
      }
      return React.cloneElement(child, {
        pl: themeGet(`space.${gap}`, gap) || null,
      });
    })}
  </StyledRow>
);

Row.propTypes = {
  gap: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
};

Row.defaultProps = {
  gap: 'l',
};

export default Row;
