import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box, { BoxStyleProps } from '../Box';

const StyledDivider = styled(Box)`
  background: transparent;
  color: ${themeGet('colors.grayscale.600')};
  display: flex;
  align-items: center;
  text-align: center;
  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${themeGet('colors.grayscale.300')};
  }
  &::before {
    margin-right: 12px;
  }
  &::after {
    margin-left: 12px;
  }
}
`;
const Divider = ({
  separatorText,
  ...props
}: BoxStyleProps & { separatorText?: any }) => {
  if (separatorText) {
    return <StyledDivider {...props}>{separatorText}</StyledDivider>;
  }

  return <Box {...props} />;
};

Divider.propTypes = {
  bg: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  separatorText: PropTypes.string,
};

Divider.defaultProps = {
  bg: 'grayscale.300',
  width: 'auto',
  height: '1px',
  position: 'relative',
  separatorText: null,
};

export default Divider;
