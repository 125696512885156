// import React from 'react';
import styled from 'styled-components';
import { themeGet, breakpoints as bp } from '@firsttable/web-theme';
import media from 'styled-media-query';
import Box from '../Box';

type SwitchType = {
  active: boolean;
  orientation: string;
};

export const SwitchBorder = styled(Box)`
  position: relative;
  border: 1px solid ${themeGet('colors.grayscale.400')};
  height: 32px;
  border-radius: 5px;
  width: ${({ width }) => width || '260px'};
  ${media.lessThan(bp.s)`
    width: 100%;
    margin: 10px 0;
  `};
`;

export const Switch = styled(Box)<SwitchType>`
  padding: 0 20px;
  line-height: 30px;
  border-radius: 5px;
  border-bottom: none;
  cursor: pointer;
  width: 50%;
  text-align: center;
  position: absolute;
  user-select: none;
  background-color: ${({ active }) =>
    active ? themeGet('colors.gold.900') : themeGet('colors.white')};
  color: ${({ active }) =>
    active ? themeGet('colors.white') : themeGet('colors.black')};
  left: ${({ active, orientation }) => {
    if (orientation === 'left') {
      return active ? '-1px' : 0;
    }
    return 'auto';
  }};
  right: ${({ active, orientation }) => {
    if (orientation === 'right') {
      return active ? '-1px' : 0;
    }
    return 'auto';
  }};
  padding-top: ${({ active }) => (active ? '1px' : 0)};
  top: ${({ active }) => (active ? '-1px' : 0)};
  bottom: ${({ active }) => (active ? '-1px' : 0)};
`;
