import React from 'react';
import PropTypes from 'prop-types';
import styledSpaces from '@firsttable/web-theme/styledSpaces';
import { Element } from 'react-scroll';
import InputField from '../InputField/InputField';
import Textarea from '../Textarea/Textarea';
import Box from '../../atoms/Box/Box';
import InputCheckbox from '../InputCheckbox/InputCheckbox';
import InputRadioGroup from '../InputRadioGroup/InputRadioGroup';
import SelectComponent from '../../atoms/Select';
import RecaptchaField from '../RecaptchaField';

const getInput = (type) => {
  switch (type) {
    case 'text':
      return InputField;
    case 'tel':
      return InputField;
    case 'email':
      return InputField;
    case 'password':
      return InputField;
    case 'textarea':
      return Textarea;
    case 'checkbox':
      return InputCheckbox;
    case 'radio':
      return InputRadioGroup;
    case 'select':
      return SelectComponent;
    case 'recaptcha':
      return RecaptchaField;
    default:
      return InputField;
  }
};

const FormInput = (props) => {
  const { type } = props;
  const Input = getInput(type);
  return <Input {...props} />;
};

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const FormField = ({
  field: { name, onChange, onBlur },
  form: { touched, errors, values, setFieldValue },
  type,
  id,
  label,
  placeholder,
  noLabel,
  options,
  ...props
}) => {
  const customFieldValues = {};
  if (type === 'recaptcha' || type === 'select') {
    // allows us to pass setFieldValue only to recaptcha so other dom elements aren't affected
    customFieldValues.setFieldValue = setFieldValue;
  }
  const spaces = styledSpaces(props);

  return (
    <Element name={name}>
      <Box {...spaces}>
        <FormInput
          type={type}
          name={name}
          label={label}
          {...props}
          value={values[name]}
          selected={values[name]}
          touched={touched[name]}
          error={errors[name]}
          id={id || name}
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          {...customFieldValues}
        />
      </Box>
    </Element>
  );
};

FormField.propTypes = {
  type: PropTypes.string,
  // name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  noLabel: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

FormField.defaultProps = {
  type: 'text',
  noLabel: false,
  id: null,
  placeholder: null,
  label: null,
  options: [],
  value: '',
  onChange: () => {},
  onBlur: () => {},
  field: {
    name: '',
    onChange: '',
    onBlur: '',
  },
  form: {
    touched: '',
    errors: [],
    values: [],
  },
};

export default FormField;
