import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { style } from 'styled-system';
import Box, { BoxStyleProps } from '../Box';
import Divider from '../Divider';
import Image from '../Image';

const flex = style({
  prop: 'width',
  cssProperty: 'flex',
  transformValue(n: any) {
    switch (n) {
      case null:
        return null;
      case 'auto':
        return '1 1 0%';
      case 'contains':
        return '0 0 auto';
      default:
        return `0 0 ${n * 100}%;`;
    }
  },
});

const maxWidth = style({
  prop: 'width',
  cssProperty: 'maxWidth',
  transformValue(n: any) {
    switch (n) {
      case null:
        return null;
      case 'auto':
      case 'contains':
        return '100%';
      default:
        return `${n * 100}%;`;
    }
  },
});

const StyledCol = styled(({ width, ...rest }) => <Box {...rest} />)`
  ${flex};
  ${maxWidth};
`;

const Col = ({
  line,
  lineColor,
  hideLineOnMobile,
  icon,
  children,
  pl,
  ...props
}: {
  line?: any;
  lineColor?: any;
  hideLineOnMobile?: any;
  icon?: any;
  children: any;
  pl?: any;
} & BoxStyleProps) => {
  const left = Array.isArray(pl) ? pl.map((i) => (i ? i / 2 : i)) : pl / 2 || 0;
  return (
    <StyledCol pl={pl} {...props}>
      {line && (
        <>
          <Divider
            display={[hideLineOnMobile ? 'none' : null, 'block']}
            height={['1px', '100%']}
            width={['100%', '1px']}
            position={['static', 'absolute']}
            left={left}
            top={0}
            my={['m', 0]}
            bg={lineColor}
          />
          {icon && (
            <Box
              display={['none', 'block']}
              position="absolute"
              top={0}
              left={left}
              ml={-30}
            >
              <Image src={icon} />
            </Box>
          )}
        </>
      )}
      {children}
    </StyledCol>
  );
};

Col.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  position: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  line: PropTypes.bool,
  hideLineOnMobile: PropTypes.bool,
};

Col.defaultProps = {
  width: 'auto',
  position: 'relative',
  line: false,
  hideLineOnMobile: false,
};

Col.displayName = 'Col';

export default Col;
