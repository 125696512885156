import gql from 'graphql-tag';

export default gql`
query validateToken {
  validateToken {
    valid
    message
    code
  }
}`;
