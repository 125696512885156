import React from 'react';
import { Link } from 'gatsby';
import { styles } from '@firsttable/web-components/atoms/Link';
import styled from 'styled-components';

const StyledNavLink = styled(Link)`
  ${styles};
`;

const NavLink = (props) => <StyledNavLink {...props} />;

export default NavLink;
