import React from 'react';
import PropTypes from 'prop-types';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { flowRight as compose, isEmpty } from 'lodash';
import { navigate } from 'gatsby';
import {
  getUser,
  hasPermission,
  errorMessage,
  isBrowser,
  loggedInStatusEvent,
} from '@firsttable/functions';
import { login as LOGIN_QUERY } from '@firsttable/graphql-queries/src';
import Cookies from 'js-cookie';
import withLocation from '../hocs/location';
import withModal from '../hocs/withModal';
import withBackUrl from '../hocs/backUrl';
import withRedirectPath from '../hocs/withRedirectPath';
import Logger from '../helpers/logger';
import { logOutUser } from '../helpers/functions';
import SignUpMoreInfo from '../components/organisms/Forms/SignUpMoreInfo';

const logger = new Logger('Auth Context');

const defaultState = {
  isLoggedIn: () => {},
  logOut: () => {},
  setUser: () => {},
  getUser: {},
  handleEmailLogin: () => {},
  handleLogin: () => {},
  handlePostLogin: () => {},
  handleGuestLogin: () => {},
};

const AuthContext = React.createContext(defaultState);

class AuthProvider extends React.Component {
  // eslint-disable-next-line react/sort-comp
  setUser = (user) =>
    isBrowser
      ? window.localStorage.setItem('user', JSON.stringify(user))
      : JSON.stringify(user);

  handleEmailLogin = (email, password) =>
    new Promise((resolve, reject) => {
      const { login } = this.props;
      login({
        variables: { email, password },
      })
        .then(({ data, error }) => {
          if (data?.createToken?.token) {
            loggedInStatusEvent({
              event: 'login',
              userId: data.createToken.member.id,
              category: 'login - email',
              loggedInStatus: 'TRUE',
            });

            this.handleLogin({
              member: data.createToken.member,
              token: data.createToken.token,
              isLoggedIn: true,
            });
            logger.debug('handleEmail Login OK', data);
            resolve({ isLoggedIn: true, ...data.createToken.member });
          }
          if (data && !data.createToken.token) {
            logger.debug('Incorrect password or email does not exist');
            reject(Error('Incorrect password or email does not exist'));
          }
          if (error) {
            logger.debug('Error', error);
            reject(Error(errorMessage(error)));
          }
        })
        .catch((error) => {
          logger.debug('There was an error logging in', error);
          reject(
            Error(
              errorMessage(
                error,
                'There was an error logging in. Please try again.',
              ),
            ),
          );
        });
    });

  handleLogin = ({ member, token, isLoggedIn }) => {
    const loginUser = member;
    logger.debug('HANDLE LOGIN', loginUser, token, isLoggedIn);
    if (loginUser.upcomingBookings.edges.length > 0) {
      Cookies.set(
        'UpcomingBookings',
        JSON.stringify(loginUser.upcomingBookings.edges),
      );
    }
    delete loginUser.upcomingBookings;
    this.setUser({
      ...loginUser,
      token,
      isLoggedIn,
    });
    logger.debug('User logged in', getUser());
  };

  handleGuestLogin = async () => {
    const { search } = this.props;
    const { backUrl } = search;
    // console.log('handling guest login', backUrl);
    Cookies.set('GuestUser', true, { expires: 1 }); // expire in 1 day
    await navigate(backUrl, { replace: true });
  };

  handlePostLogin = async (data = {}, formState = {}) => {
    const {
      hideModal,
      backUrl,
      setBackUrl,
      updateRedirect,
      redirectPath,
      showModal,
      isModalOpen,
      search,
    } = this.props;
    const { setStatus, setSubmitting } = formState;
    const { isLoggedIn, createFacebookMember, isSocialLogin, onAfterLogin } =
      data;
    const { backUrl: queryBackUrl } = search;
    if (setStatus || setSubmitting) {
      setStatus(false);
      setSubmitting(false);
    }
    // prevent GTM tracking admins
    if (hasPermission('RESTAURANT_ADMINISTRATION') || hasPermission('ADMIN')) {
      Cookies.set('Admin', true, { secure: false });
      Cookies.set('AdminComputer', true, { secure: false });
    }

    Cookies.set('PastLoggedIn', true, { expires: 365, path: '/' });

    if (createFacebookMember && !createFacebookMember.member.termsAndPrivacy) {
      return showModal(<SignUpMoreInfo />);
    }
    if (!isLoggedIn && !isSocialLogin) {
      setStatus({
        message: 'There was an error signing you in',
        type: 'danger',
      });
      setSubmitting(false);
      return {
        authError: true,
        redirected: false,
      };
    }

    // if an on after login is passed, this is handled before all other logic
    if (onAfterLogin) {
      onAfterLogin();
      return {
        authError: false,
        redirected: true, // assume redirect is handled in onAfterLogin closure
      };
    }
    // if back url is set via url
    if (queryBackUrl) {
      await navigate(queryBackUrl, { replace: true });
      return {
        authError: false,
        redirected: true,
      };
    }
    // redirectPath is a redirect stored in localstate, handy for complex redirects
    // like processing bookings for non auth'd users
    if (redirectPath) {
      // reset redirect path
      updateRedirect(null);
      await navigate(redirectPath, { replace: true });
      return {
        authError: false,
        redirected: true,
      };
    }
    if (isModalOpen) {
      hideModal();
    }
    // backUrl is global hook redirect.. handy for simple redirects
    if (backUrl) {
      await navigate(backUrl, { replace: true });
      setBackUrl(null); // reset backURL

      return {
        authError: false,
        redirected: true,
      };
    }

    return {
      authError: false,
      redirected: false,
    };
  };

  isLoggedIn = () => {
    const user = getUser();
    // logger.debug('Is logged in', user);
    return !isEmpty(user) && !!user.isLoggedIn;
  };

  logOut = async (redirectTo = null) => {
    const { client, location, setBackUrl } = this.props;
    const { FB } = window;
    const user = getUser();

    loggedInStatusEvent({
      event: 'logout',
      userId: user.id,
      category: 'logout',
      loggedInStatus: 'FALSE',
    });

    await logOutUser(client, redirectTo || location);
    if (FB) {
      // handle FB session logout
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          FB.logout();
        }
      });
    }

    Cookies.remove('LaunchAlert');
    // reset backUrl
    setBackUrl(null);
  };

  state = { // eslint-disable-line
    isLoggedIn: this.isLoggedIn,
    isUserLoggedIn: this.isLoggedIn(),
    logOut: this.logOut,
    setUser: this.setUser,
    getUser: getUser(),
    handleEmailLogin: this.handleEmailLogin,
    handleLogin: this.handleLogin,
    handlePostLogin: this.handlePostLogin,
    handleGuestLogin: this.handleGuestLogin,
  };

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  login: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  location: PropTypes.shape(),
  hideModal: PropTypes.func.isRequired,
  updateRedirect: PropTypes.func,
  backUrl: PropTypes.string,
  redirectPath: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  setBackUrl: PropTypes.func.isRequired,
  search: PropTypes.shape(),
};
AuthProvider.defaultProps = {};

export default AuthContext;

const Provider = compose(
  withLocation,
  withApollo,
  withBackUrl,
  withModal,
  withRedirectPath,
  graphql(LOGIN_QUERY, { name: 'login' }),
)(AuthProvider);
export { Provider };
