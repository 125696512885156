"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YYYYMMDD = exports.getOrdinal = exports.formattedTime = exports.formattedDayMonthYear = exports.getMonth = exports.getDayOfMonth = exports.getDayName = void 0;
var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
// 23
var getDayOfMonth = function (d) { return d.getDate(); };
exports.getDayOfMonth = getDayOfMonth;
var dateLengthFormatter = function (d, f) {
    var r = d;
    switch (f) {
        case 's':
            r = d.slice(0, 3);
            break;
        case 'l':
            return r;
        default:
            break;
    }
    return r;
};
/**
 *
 * @param d
 * @param f format of day, l for long, s for short 3 character
 */
var getMonth = function (d, f) {
    if (f === void 0) { f = 'l'; }
    return dateLengthFormatter(months[d.getMonth()], f);
};
exports.getMonth = getMonth;
/**
 * Get ordinal for date st, nd, rd, th, etc
 *
 * @param d
 * @return string
 */
var getOrdinal = function (d) {
    var specials = [null, 'st', 'nd', 'rd']; // 1st, 2nd, 3rd... 31st
    var day = +getDayOfMonth(d); // 1-31
    // 11-13 are 'th' ordinals rather than 11st, 12nd, 13rd
    var lastDigit = [11, 12, 13].includes(day) ? 0 : day % 10;
    return specials[lastDigit] || 'th'; // lookup ordinal or default to th
};
exports.getOrdinal = getOrdinal;
/**
 * get the day by name
 * @param d
 * @param f format
 */
var getDayName = function (d, f) {
    if (f === void 0) { f = 'l'; }
    return dateLengthFormatter(days[d.getDay()], f);
};
exports.getDayName = getDayName;
// e.g. Thu, 23 January 2019
var formattedDayMonthYear = function (d) {
    var date = getDayOfMonth(d);
    var monthName = getMonth(d, 'l');
    var dayName = getDayName(d, 's'); // Thu
    var year = d.getFullYear(); // 2019
    return dayName + ", " + date + " " + monthName + " " + year;
};
exports.formattedDayMonthYear = formattedDayMonthYear;
var formattedTime = function (time) {
    var now = new Date();
    var nowWithTime = new Date(now.toDateString() + ", " + time);
    var hours = nowWithTime.getHours();
    var minutes = String(nowWithTime.getMinutes()).padStart(2, '0');
    var amOrPm = hours >= 12 ? 'pm' : 'am';
    var hour = hours % 12 || 12;
    return hour + ":" + minutes + " " + amOrPm;
};
exports.formattedTime = formattedTime;
var YYYYMMDD = function (date) {
    return date.getFullYear() + "-" + String(date.getMonth()).padStart(2, '0') + "-" + String(date.getDay()).padStart(2, '0');
};
exports.YYYYMMDD = YYYYMMDD;
