import gql from 'graphql-tag';

export default gql`
  mutation createBooking(
    $restaurantId: Int
    $date: String
    $time: String
    $availabilityId: Int!
  ) {
    createBooking(
      restaurantId: $restaurantId
      date: $date
      time: $time
      availabilityId: $availabilityId
    ) {
      status
      reference
      restaurant {
        id
        region {
          id
          menuTitle
          slug
        }
      }
    }
  }
`;
