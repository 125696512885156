import React from 'react';
import PropTypes from 'prop-types';
import { BaseFormProps } from '@firsttable/types';
import Form from '../../molecules/Form';
import Box from '../../atoms/Box';
import Button from '../../atoms/Button';
import FocusError from '../../molecules/FocusError';
import FormMessage from '../../atoms/Alert';

interface FormActionProps {
  isSubmitting: boolean;
  Action: any;
  actionLabel: string;
}
const FormAction = ({ Action, isSubmitting, actionLabel }: FormActionProps) => {
  if (!React.isValidElement(Action) && !actionLabel) {
    return null;
  }
  return (
    <>
      {Action || (
        <Box mt={['20px', '30px']}>
          <Button size="l" kind="cta" type="submit" isLoading={isSubmitting}>
            {actionLabel || 'Update'}
          </Button>
        </Box>
      )}
    </>
  );
};

FormAction.propTypes = {
  Action: PropTypes.any,
  isSubmitting: PropTypes.bool,
  actionLabel: PropTypes.string,
};

FormAction.defaultProps = {
  Action: null,
  isSubmitting: false,
  actionLabel: null,
};

const FormLayout = ({
  handleSubmit,
  isSubmitting,
  status,
  children,
  Action,
  actionLabel,
  isModal,
  ...props
}: BaseFormProps) => (
  <Form onSubmit={handleSubmit} status={status} {...props}>
    {status && status.message && (
      <FormMessage m="20px 0" message={status.message} type={status.type} />
    )}
    {children}
    <FocusError isModal={isModal} />
    <FormAction
      Action={Action}
      isSubmitting={isSubmitting}
      actionLabel={actionLabel}
    />
  </Form>
);

FormLayout.defaultProps = {
  status: false,
  Action: null,
  isSubmitting: false,
  isModal: false,
  actionLabel: null,
};
FormLayout.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.func,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isModal: PropTypes.bool,
  children: PropTypes.any.isRequired,
  Action: PropTypes.any,
  actionLabel: PropTypes.string,
};

export default FormLayout;
