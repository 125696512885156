import React, { useEffect } from 'react';
import { isBrowser } from '@firsttable/functions';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import Recaptcha from 'react-recaptcha';
import Text from '../../atoms/Text';
import Box from '../../atoms/Box';

const SITE_KEY =
  isBrowser && !window.Cypress
    ? '6LePCGsUAAAAAJq6ArSfabajH69fZHK1oSxJ759c'
    : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
const RecaptchaField = ({ setFieldValue, error, touched, id }) => {
  useEffect(() => {
    if (!document.getElementById('recaptchaScript')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.id = 'recaptchaScript';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);
  return (
    <Box m="10px 0 25px 0" data-testid="field__recaptcha">
      <Recaptcha
        sitekey={SITE_KEY}
        render="explicit"
        elementID={id || uniqid()} // Note: https://github.com/appleboy/react-recaptcha/issues/262
        name="recaptcha"
        verifyCallback={(response) => {
          setFieldValue('recaptcha', response);
        }}
        onloadCallback={() => {
          /* console.log('done loading!'); */
        }}
      />
      {touched && error && (
        <Text color="error" fontSize="xs" mt="5px" mb="0">
          {error}
        </Text>
      )}
    </Box>
  );
};

RecaptchaField.propTypes = {
  id: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  touched: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
};
RecaptchaField.defaultProps = {
  error: null,
  touched: null,
  id: null,
};

export default RecaptchaField;
