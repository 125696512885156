import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, space, width, height } from 'styled-system';
// @ts-ignore
import { ReactComponent as AvatarComponent } from './avatar.s.svg';
import { BoxStyleProps } from '../Box';

const StyledAvatar = styled(AvatarComponent)`
  vertical-align: middle;
  ${color};
  ${space};
  ${width};
  ${height};
`;

const Avatar = (props: BoxStyleProps) => <StyledAvatar {...props} />;

Avatar.propTypes = {
  color: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
};
Avatar.defaultProps = {
  viewBox: '0 0 56 56',
};

Avatar.displayName = 'Avatar';

export default Avatar;
