import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { switchProp } from 'styled-tools';
import NoSSR from 'react-no-ssr';
import * as theme from '@firsttable/web-theme';
import { isMobile } from 'react-device-detect';
import Box from '../Box';
import Text from '../Text';

// todo fix componentWillReceiveProps see: https://github.com/JedWatson/react-select/issues/3720
const height = (size) => {
  switch (size) {
    case 's':
      return '40px';
    case 'l':
      return '54px';
    default:
      return '50px';
  }
};
const padding = (size) => {
  switch (size) {
    case 's':
      return '0 5px 0 10px';
    case 'l':
      return '0 10px 0 18px';
    default:
      return '0 7px 0 14px';
  }
};

const borderColor = (pure, isDisabled, isFocused, error, touched) => {
  if (error && touched) {
    return theme.colors.error;
  }
  if (!pure) {
    return theme.colors.grayscale[isDisabled ? 300 : isFocused ? 600 : 400]; /* eslint-disable-line */
  }
  return 'transparent';
};

const SSRLoading = styled(Box)`
  background: #fff;
  color: ${themeGet('colors.grayscale.600')};
  display: flex;
  align-items: center;
  cursor: default;
  ${({ pure }) =>
    pure !== 'true' &&
    css`
      border: 1px solid ${themeGet('colors.grayscale.400')};
      border-radius: 2px;
      ${switchProp('size', {
        s: css`
          height: 40px;
          padding: 0 12px;
        `,
        m: css`
          height: 50px;
          padding: 0 16px;
        `,
        l: css`
          height: 54px;
          padding: 0 20px;
        `,
      })};
    `}
  ${({ pure, styles }) =>
    pure === 'true' &&
    css`
      height: ${styles[0].height};
      padding: ${styles[0].padding};
      @media (min-width: 768px) {
        height: ${styles[1].height};
        padding: ${styles[1].padding};
      }
      @media (min-width: 992px) {
        height: ${styles[2].height};
        padding: ${styles[2].padding};
      }
    `}
`;

const SelectComponent = (props) => {
  const {
    label,
    options,
    size,
    pure,
    error,
    placeholder,
    touched,
    id,
    styles,
    name,
    value,
    onBlur,
    setFieldValue,
    customOnChangeFunc,
    inlineError,
  } = props;

  const defaultSettings = {
    control: (base, { isDisabled, isFocused }) => ({
      ...base,
      borderRadius: '2px',
      background: isDisabled ? theme.colors.grayscale[300] : '#fff',
      boxShadow: 'none',
      height: height(size),
      padding: padding(size),
      borderColor: borderColor(pure, isDisabled, isFocused, error, touched),
      ':hover': {
        borderColor: !pure
          ? theme.colors.grayscale[isFocused ? 600 : 400]
          : 'transparent',
      },
      '@media screen and (min-width: 0)': styles[0],
      '@media screen and (min-width: 768px)': styles[1],
      '@media screen and (min-width: 992px)': styles[2],
      width: '100%',
      cursor: 'pointer',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),
    menu: (base) => ({
      ...base,
      boxShadow: '0px 8px 30px rgba(39, 43, 46, 0.1)',
      zIndex: 2,
    }),
    option: (base, { isSelected }) => {
      const color = isSelected ? theme.colors.brand.blue : '#fff';
      return {
        ...base,
        padding: '10px 16px',
        background: color,
        ':hover': {
          background: !isSelected ? theme.colors.grayscale[300] : null,
        },
        ':active': {
          background: color,
        },
        cursor: 'pointer',
      };
    },
  };

  return (
    <NoSSR
      onSSR={
        <SSRLoading size={size} pure={pure.toString()} styles={styles}>
          Loading...
        </SSRLoading>
      }
    >
      {label ? (
        <Box mb="6px">
          <label htmlFor={id}>{label}</label>
        </Box>
      ) : null}
      <Box>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id}>
          <Select
            {...props}
            name={name}
            inputId={name}
            id={id}
            styles={defaultSettings}
            options={options}
            placeholder={placeholder}
            value={
              options ? options.find((option) => option.value === value) : ''
            }
            isSearchable={!isMobile}
            onChange={(option) => {
              if (customOnChangeFunc) {
                return customOnChangeFunc(option);
              }
              return setFieldValue(name, option?.value || null);
            }}
            error={touched && error ? 1 : 0}
            onBlur={onBlur}
          />
        </label>

        {touched && error && inlineError && (
          <Text color="error" fontSize="xs" mt="5px" mb="0">
            {error}
          </Text>
        )}
      </Box>
    </NoSSR>
  );
};

SelectComponent.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  label: PropTypes.string,
  pure: PropTypes.bool,
  inlineError: PropTypes.bool,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  touched: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  id: PropTypes.string,
  field: PropTypes.shape(),
  styles: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  customOnChangeFunc: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectComponent.defaultProps = {
  placeholder: 'Select',
  name: 'select',
  size: 'm',
  id: '',
  label: '',
  pure: false,
  onBlur: () => {},
  error: false,
  touched: false,
  field: { errors: false },
  customOnChangeFunc: null,
  styles: [],
  value: null,
  inlineError: true,
};

export default SelectComponent;
