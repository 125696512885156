import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { height as heightStyled, border, borderRadius } from 'styled-system';
import Box from '../../atoms/Box';
import Text from '../../atoms/Text';

const height = (props) => {
  const { size } = props;
  switch (size) {
    case 's':
      return '40px';
    case 'l':
      return '54px';
    default:
      return '50px';
  }
};
const fontSize = (props) => {
  const { size } = props;
  let fs = '';
  switch (size) {
    case 's':
      fs = 's';
      break;
    default:
      fs = 'm';
      break;
  }
  return themeGet(`fontSizes.${fs}`);
};
const padding = (props) => {
  const { size } = props;
  switch (size) {
    case 's':
      return '0 12px';
    case 'l':
      return '0 20px';
    default:
      return '0 16px';
  }
};

const StyledInput = styled.input`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: ${height};
  padding: ${padding};
  outline: 0;
  -webkit-appearance: none;
  line-height: normal;
  font-size: ${fontSize}px;
  background: ${({ disabled }) =>
    disabled ? themeGet('colors.grayscale.300') : themeGet('colors.white')};
  border: 1px solid
    ${({ disabled }) =>
      disabled
        ? themeGet('colors.grayscale.300')
        : themeGet('colors.grayscale.400')};
  border-color: ${({ error }) => (error ? themeGet('colors.error') : null)};
  border-color: ${({ success }) =>
    success ? themeGet('colors.success') : null};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : null)};
  &:focus {
    border-color: ${themeGet('colors.grayscale.600')};
  }
  ${heightStyled};
  ${border};
  ${borderRadius};
`;

const InputField = ({
  label,
  id,
  name,
  success,
  error,
  touched,
  onChange,
  onBlur,
  value,
  ...props
}) => (
  <>
    {label ? (
      <Box mb="6px">
        <label htmlFor={id} id={`${name}label`}>
          {label}
        </label>
      </Box>
    ) : null}
    <StyledInput
      id={id}
      {...props}
      name={name}
      value={value}
      success={success}
      error={touched && error ? 1 : 0}
      onChange={onChange}
      onBlur={onBlur}
    />
    {touched && error && (
      <Text color="error" fontSize="xs" mt="5px" mb="0">
        {error}
      </Text>
    )}
    {success ? (
      <Text color="success" fontSize="xs" mt="5px" mb="0">
        {success}
      </Text>
    ) : null}
  </>
);

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  error: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  success: PropTypes.string,
  borderRadius: PropTypes.number,
  touched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.any,
};
InputField.defaultProps = {
  type: 'text',
  size: 'm',
  borderRadius: 2,
  success: null,
  label: null,
  id: null,
  error: null,
  touched: false,
  value: '',
};

export default InputField;
