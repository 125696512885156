import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  gql,
  Observable,
  NormalizedCacheObject,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';
import { getUser, isBrowser, refreshUser } from '@firsttable/functions';
import { SentryLink } from 'apollo-link-sentry';
import { logOutUser } from '../helpers/functions';
import { cache } from './cache';

import Logger from '../helpers/logger';

const logger = new Logger('Apollo');
const isDevelopment = process.env.NODE_ENV === 'development';

let apolloClient: ApolloClient<NormalizedCacheObject> | null;

export const typeDefs = gql`
  type RestaurantSearch {
    restaurantSort: String
    selectedDate: String
    city: String
    session: String
    userLat: Float
    userLng: Float
  }
  extend type Query {
    redirectPath: String
    restaurantSearch: RestaurantSearch
  }
`;

const customFetch = (uri: string, options: any) => {
  if (!isBrowser && process.env.DEBUG_MODE) {
    console.log(
      '---------(request run in SSR) request body: ',
      uri,
      options.body,
    );
  }
  return fetch(uri, options).then((response: any) => {
    if (!isBrowser) {
      //  && process.env.DEBUG_MODE
      console.log(
        '---------(request run in SSR) response status: ',
        response.status,
        uri,
        options.body,
      );
    }
    if (response.status >= 500) {
      // or handle 400 errors
      return Promise.reject(response.status);
    }
    return response;
  });
};

const httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
  fetch: customFetch,
});

const authLink = new ApolloLink((operation, forward) => {
  const loggedInUser = getUser();

  const authToken =
    loggedInUser && loggedInUser.isLoggedIn && loggedInUser.token
      ? { 'Application-Authorization': `Bearer ${loggedInUser.token}` }
      : {};
  operation.setContext((context: any) => {
    const headers = {
      ...context.headers,
      ...authToken,
      Origin: process.env.GATSBY_SITE_ORIGIN,
    };
    return {
      ...context,
      headers,
    };
  });
  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) =>
    new Observable((observer: any) => {
      const user = getUser();
      if (graphQLErrors) {
        if (!user?.token) {
          logger.debug('NO USER TOKEN', graphQLErrors);
          const { location } = window;

          const re = '/(profile|review|gift-vouchers|booking|invite|auth)/';
          // don't continue to execute query on these routes
          if (location.pathname.match(re)) {
            return null;
          }
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          return forward(operation).subscribe(subscriber);
        }
        graphQLErrors.map(async (err: any, index: number) => {
          switch (err.status) {
            case 'UNAUTHORIZED': {
              logger.debug('UNAUTHORIZED USER', err);

              const retryRequest = (userObj: any) => {
                const { headers } = operation.getContext();
                logger.debug('RETRY REQUEST', userObj?.token);
                operation.setContext({
                  headers: {
                    ...headers,
                    'Application-Authorization': userObj?.token
                      ? `Bearer ${userObj.token}`
                      : null,
                  },
                });

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                return forward(operation).subscribe(subscriber);
              };

              const handleUserLogOut = async () => {
                logger.debug('USER LOGGED OUT', graphQLErrors[index]);
                await logOutUser(apolloClient, {
                  authError: true,
                  ...window.location,
                });
                return observer.error(graphQLErrors[index]);
              };

              if (!user.token) {
                await handleUserLogOut();
              }

              try {
                // refresh token
                // @ts-ignore
                const newUser: any = await refreshUser(apolloClient);

                if (!newUser.token) {
                  logger.debug('REFRESH NOT OK', newUser);
                  await handleUserLogOut();
                }
                logger.debug('REFRESH OK', newUser.token);

                return retryRequest(newUser);
              } catch (e) {
                await handleUserLogOut();
              }

              logger.debug('UNABLE TO HANDLE ERROR');

              return observer.error(new Error('Please log in'));
            }
            default:
              logger.debug('GENERAL ERROR', graphQLErrors[index]);
              return observer.error(graphQLErrors[index]);
          }
        });
      }

      if (networkError) {
        return observer.error(networkError);
      }
    }),
);

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    ssrMode: !isBrowser,
    cache,
    typeDefs,
    resolvers: {},
    link: ApolloLink.from([authLink, errorLink, new SentryLink(), httpLink]),
    connectToDevTools: isDevelopment,
  });
}

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
