import React, { ReactNode } from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import Box, { BoxStyleProps } from '../Box';

const color = ({ type }: { type: string }) => {
  switch (type) {
    case 'warning':
      return themeGet('colors.warning.light');
    case 'promotion':
      return themeGet('colors.grayscale.600');
    default:
      return themeGet('colors.white');
  }
};

const bg = ({ type }: { type: string }) => {
  switch (type) {
    case 'primary':
      return themeGet('colors.brand.blue');
    case 'promotion':
      return themeGet('colors.white');
    case 'secondary':
      return themeGet('colors.grayscale.600');
    case 'success':
      return themeGet('colors.success');
    case 'danger':
    case 'error':
      return themeGet('colors.error');
    case 'warning':
      return themeGet('colors.warning.dark');
    case 'bird':
      return 'transparent';
    default:
      return themeGet('colors.gold.800');
  }
};

const StyledAlert = styled(Box)`
  border-radius: 3px;
  background: ${bg};
  color: ${color};
  position: relative;
  white-space: break-spaces;
`;

const CloseIcon = styled(Box)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
  top: 4px;
  overflow: hidden;
  text-indent: 100%;
  cursor: pointer;
  backface-visibility: hidden;
  &:hover,
  &:focus {
    outline: none;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    background: ${color};
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

type AlertType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'error'
  | 'warning'
  | 'info';

export interface AlertProps {
  message?: string | ReactNode;
  clearMessages?: () => void;
  close?: () => void;
  type?: AlertType;
  options?: any;
}
const Alert = ({
  message = 'Message',
  clearMessages,
  close,
  type = 'info',
  options,
  fontSize = ['xs', 's'],
  lineHeight = 's',
  p = ['8px 16px', '13px 24px'],
  ...rest
}: AlertProps & BoxStyleProps) => {
  const alertType = options?.type || type;
  const closeIconStyles = options?.closeIconStyle || {};

  return (
    <StyledAlert
      id="form-alert"
      type={alertType}
      width={options?.width}
      options={options}
      fontSize={fontSize}
      lineHeight={lineHeight}
      p={p}
      {...rest}
    >
      {message}
      {close && (
        <CloseIcon
          type={alertType}
          onClick={close}
          style={{ ...closeIconStyles.style }}
        />
      )}
    </StyledAlert>
  );
};

export default Alert;
