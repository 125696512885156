import React from 'react';
import Text from '@firsttable/web-components/atoms/Text';
import { Field } from 'formik';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import Link from '@firsttable/web-components/atoms/Link';
import PropTypes from 'prop-types';
import NavLinkComponent from '../../../atoms/NavLink';

const TermsAndConditionsField = ({ name, id, ...props }) => (
  <>
    <Text fontSize="s">
      We want you to know exactly how our service works and why we need your
      registration details. Please state that you’ve read and agreed to these
      terms before continuing.
    </Text>
    <Field
      component={FormField}
      mb="25px"
      type="checkbox"
      name={name}
      id={id || name}
      label={
        <>
          I agree to the{' '}
          <Link
            NavComponent={NavLinkComponent}
            underline
            color="brand.red"
            to="/terms-and-conditions/"
            target="_blank"
          >
            terms and conditions
          </Link>
        </>
      }
      {...props}
    />
  </>
);

TermsAndConditionsField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

TermsAndConditionsField.defaultProps = {
  id: null,
};

export default TermsAndConditionsField;
