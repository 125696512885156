import PropTypes from 'prop-types';
import {
  space,
  display,
  color,
  MarginProps,
  PaddingProps,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

type BreadcrumbProps = MarginProps & PaddingProps;

const Breadcrumbs = styled.ul<BreadcrumbProps>`
  list-style: none;
  ${display};
  ${color};
  ${space};

  li {
    display: inline-block;
    margin-right: 30px;

    &:last-child::after {
      display: none;
    }
  }

  li::after {
    content: ' ';
    position: absolute;
    width: 1px;
    margin: 4px 0 0 15px;
    transform: rotate(20deg) translate3d(0, 0, 0);
    height: 20px;
    background: ${themeGet('colors.grayscale.400')};

    @media screen and (min-width: 768px) {
      margin-top: -2px;
      height: 29px;
    }
  }
`;

Breadcrumbs.propTypes = {
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  color: PropTypes.string,
};
Breadcrumbs.defaultProps = {
  mb: 's',
  p: 0,
  color: 'grayscale.600',
};

export default Breadcrumbs;
