import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  space,
  maxWidth,
  fontWeight,
  fontSize as fs,
  display,
  // SpaceProps
} from 'styled-system';
import Icon from '../Icon';
import Dots from '../Dots';
import Text from '../Text';
// @ts-ignore
import facebook from './facebook.svg';

const width = ({ iswide }: ThemedStyledProps<any, any>) =>
  iswide === 'true' ? '100%' : null;
const cursor = ({ disabled }: { disabled: any }) =>
  disabled ? 'default' : 'pointer';

const padding = (props: any) => {
  const { size, subtext } = props;
  if (subtext) {
    if (size === 'l') {
      return '5px 24px 7px';
    }
    return '4px 24px 6px';
  }
  switch (size) {
    case 's':
      return '8px 16px';
    case 'l':
      return '13px 24px';
    default:
      return '12px 24px';
  }
};
const fontSize = (props: any) => {
  const { size } = props;
  switch (size) {
    case 's':
      return themeGet('fontSizes.xs');
    case 'l':
      return themeGet('fontSizes.m');
    default:
      return themeGet('fontSizes.s');
  }
};
const btnColor = (props: any) => {
  const { kind } = props;
  switch (kind) {
    case 'white':
      return themeGet('colors.black');
    case 'outline':
      return themeGet('colors.brand.blue');
    default:
      return themeGet('colors.white');
  }
};
const btnBg = ({ kind, bg }: any) => {
  if (bg) return bg;
  switch (kind) {
    case 'cta':
      return themeGet('colors.brand.red');
    case 'normal':
      return themeGet('colors.brand.blue');
    case 'white-transparent':
      return themeGet('colors.footer.btn');
    case 'facebook':
    case 'facebook-share':
      return themeGet('colors.facebook');
    default:
      return themeGet('colors.white');
  }
};
const btnBgHover = (props: any) => {
  const { kind } = props;
  switch (kind) {
    case 'cta':
      return themeGet('colors.buttons.cta.hover');
    case 'normal':
      return themeGet('colors.buttons.normal.hover');
    case 'white-transparent':
      return themeGet('colors.footer.btnHover');
    case 'facebook':
    case 'facebook-share':
      return themeGet('colors.buttons.facebook.hover');
    default:
      return themeGet('colors.white');
  }
};
const btnBgActive = (props: any) => {
  const { kind } = props;
  switch (kind) {
    case 'cta':
      return themeGet('colors.buttons.cta.active');
    case 'normal':
      return themeGet('colors.buttons.normal.active');
    case 'white-transparent':
      return themeGet('colors.footer.btnActive');
    case 'facebook':
    case 'facebook-share':
      return themeGet('colors.buttons.facebook.active');
    default:
      return themeGet('colors.buttons.outline.active');
  }
};
const btnBorder = (props: any) => {
  const { kind } = props;
  switch (kind) {
    case 'cta':
      return themeGet('colors.brand.red');
    case 'normal':
      return themeGet('colors.brand.blue');
    case 'white':
      return themeGet('colors.white');
    case 'white-transparent':
      return 'transparent';
    case 'facebook':
    case 'facebook-share':
      return themeGet('colors.facebook');
    default:
      return themeGet('colors.grayscale.400');
  }
};
const btnBorderHover = (props: any) => {
  const { kind } = props;
  switch (kind) {
    case 'cta':
      return themeGet('colors.buttons.cta.hover');
    case 'normal':
      return themeGet('colors.buttons.normal.hover');
    case 'white':
      return themeGet('colors.white');
    case 'white-transparent':
      return 'transparent';
    case 'facebook':
    case 'facebook-share':
      return themeGet('colors.buttons.facebook.hover');
    default:
      return themeGet('colors.buttons.outline.hover');
  }
};
const btnBorderActive = (props: any) => {
  const { kind } = props;
  switch (kind) {
    case 'cta':
      return themeGet('colors.buttons.cta.active');
    case 'normal':
      return themeGet('colors.buttons.normal.active');
    case 'white-transparent':
      return 'transparent';
    case 'facebook':
    case 'facebook-share':
      return themeGet('colors.facebook');
    default:
      return themeGet('colors.buttons.outline.active');
  }
};
const dotsColor = (kind: string | undefined) => {
  switch (kind) {
    case 'cta':
    case 'white-transparent':
    case 'facebook':
    case 'normal':
      return 'white';
    default:
      return undefined;
  }
};

type StyleProps = {
  disabled: any;
};
export const styles = css<StyleProps>`
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  outline: none;
  user-select: none;
  transition: 0.1s ease;
  border-radius: 2px;
  letter-spacing: -0.5px;
  padding: ${padding};
  width: ${width};
  cursor: ${cursor};
  color: ${btnColor};
  background: ${btnBg};
  border: 1px solid ${btnBorder};
  font-size: ${fontSize}px;
  line-height: ${themeGet('lineHeights.m')};
  ${({ disabled }) =>
    !disabled
      ? css`
          &:hover {
            background: ${btnBgHover};
            border-color: ${btnBorderHover};
          }
          &:active {
            background: ${btnBgActive};
            border-color: ${btnBorderActive};
          }
        `
      : null};
  ${space};
  ${maxWidth};
  ${fontWeight};
  ${fs};
  ${display};
`;

const StyledButton = styled.button`
  ${styles};
`;

const Button = ({
  to,
  href,
  type,
  arrow,
  icon,
  wide,
  isLoading,
  role,
  disabled,
  children,
  NavComponent,
  ...rest
}: any) => {
  const props = {
    iswide: wide.toString(),
    children,
    ...rest,
  };
  const { kind, subtext } = props;
  const btnProps = {
    type,
    role,
    'aria-label': children,
    ...props,
  };
  // set submit state as false for SSR to prevent form submissions before react is loaded
  const [submitState, setSubmitState] = useState(false);
  useEffect(() => {
    if (type === 'submit') {
      setSubmitState(true);
    }
  }, []);
  const content = isLoading ? (
    <Dots mt="-2px" color={dotsColor(kind)} />
  ) : (
    <>
      {icon && <Icon mr="5px" mt="-3px" name={icon} color="inherit" />}
      {kind === 'facebook' ? (
        <img src={facebook} alt="with facebook" />
      ) : (
        <>
          {children}
          {subtext && (
            <Text
              mt={-3}
              mb={0}
              as="span"
              display="block"
              fontSize="11px"
              fontWeight={400}
            >
              {subtext}
            </Text>
          )}
        </>
      )}
      {arrow && <Icon ml="5px" mt="-3px" name="s-arrow-down" color="inherit" />}
    </>
  );

  if (isLoading || (type === 'submit' && !submitState)) {
    return (
      <StyledButton disabled isLoading {...btnProps}>
        {content}
      </StyledButton>
    );
  }
  if (disabled) {
    return (
      <StyledButton disabled {...btnProps}>
        {content}
      </StyledButton>
    );
  }
  // @todo in order to support Next and Gatsby. Can be removed when gatsby no longer supported i.e. by using href= in Next based <Button>
  if ((href && NavComponent) || (process.env.NEXT_PUBLIC_SITE_ID && to)) {
    return (
      <NavComponent href={to || href}>
        <StyledButton {...btnProps}>{content}</StyledButton>
      </NavComponent>
    );
  }
  if (to) {
    return (
      <NavComponent to={to} {...props}>
        {content}
      </NavComponent>
    );
  }
  if (href) {
    return (
      <StyledButton as="a" href={href} {...btnProps}>
        {content}
      </StyledButton>
    );
  }
  // for an external link as a button
  return <StyledButton {...btnProps}>{content}</StyledButton>;
};

Button.propTypes = {
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontWeight: PropTypes.string,
  kind: PropTypes.oneOf([
    'normal',
    'cta',
    'outline',
    'white',
    'white-transparent',
    'facebook',
    'facebook-share',
  ]),
  size: PropTypes.oneOf(['s', 'm', 'l']),
  arrow: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  isLoading: PropTypes.bool,
  role: PropTypes.string,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  subtext: PropTypes.string,
  NavComponent: PropTypes.any,
  // @ts-ignore
  ...space.propTypes,
  // @ts-ignore
  ...maxWidth.propTypes,
};

Button.defaultProps = {
  kind: 'normal',
  size: 'm',
  fontWeight: 'bold',
  display: 'inline-block',
  icon: '',
  type: 'button',
  role: 'button',
  arrow: false,
  isLoading: false,
  wide: false,
  disabled: false,
  href: null,
  to: null,
  subtext: null,
  NavComponent: null,
};

Button.displayName = 'Button';

export default Button;
